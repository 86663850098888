/*----------------------------------------------------------------*/
/* Helpers
/*----------------------------------------------------------------*/
#cover-right-widget .crw-load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  /*change these sizes to fit into your project*/
  height: 100px;
}
#cover-right-widget .crw-load hr {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite;
}
#cover-right-widget .crw-no-padding {
  padding: 0 !important;
}
#cover-right-widget .crw-no-margin {
  margin: 0 !important;
}
#cover-right-widget .crw-no-radius {
  border-radius: 0;
}
#cover-right-widget .crw-divider-vertical {
  background: rgba(0, 0, 0, 0.12);
  height: 24px;
  width: 1px;
  margin: 0 8px;
}
#cover-right-widget .crw-hidden {
  visibility: hidden;
  opacity: 0;
}
#cover-right-widget [fill-width] {
  min-width: 100%;
}
#cover-right-widget .crw-fill-width {
  width: 100%;
}
#cover-right-widget [fill-height] {
  min-height: 100%;
}
#cover-right-widget .crw-full-height {
  height: 100% !important;
}
#cover-right-widget a img {
  display: block;
  outline: none;
}
#cover-right-widget img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none;
}
#cover-right-widget .crw-scrollable {
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
#cover-right-widget .crw-scrollable.crw-only-x {
  overflow-x: auto;
  overflow-y: hidden;
}
#cover-right-widget .crw-scrollable.crw-only-y {
  overflow-x: hidden;
  overflow-y: auto;
}
#cover-right-widget .crw-flex-scrollable {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
#cover-right-widget .crw-flex-scrollable.crw-only-x {
  overflow-x: auto;
  overflow-y: hidden;
}
#cover-right-widget .crw-flex-scrollable.crw-only-y {
  overflow-x: hidden;
  overflow-y: auto;
}
#cover-right-widget .crw-background-image-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
#cover-right-widget .crw-align-vertical:before {
  display: inline-block;
  height: 100%;
  content: "";
  vertical-align: middle;
}
#cover-right-widget .crw-align-vertical .crw-align-vertical-top, #cover-right-widget .crw-align-vertical .crw-align-vertical-middle, #cover-right-widget .crw-align-vertical .crw-align-vertical-bottom {
  display: inline-block;
}
#cover-right-widget .crw-align-vertical .crw-align-vertical-top {
  vertical-align: top;
}
#cover-right-widget .crw-align-vertical .crw-align-vertical-middle {
  vertical-align: middle;
}
#cover-right-widget .crw-align-vertical .crw-align-vertical-bottom {
  vertical-align: bottom;
}
#cover-right-widget .crw-padding-0, #cover-right-widget .crw-p-0 {
  padding: 0px;
}
#cover-right-widget .crw-padding-top-0, #cover-right-widget .crw-pt-0 {
  padding-top: 0px;
}
#cover-right-widget .crw-padding-right-0, #cover-right-widget .crw-pr-0 {
  padding-right: 0px!important;
}
#cover-right-widget .crw-padding-bottom-0, #cover-right-widget .crw-pb-0 {
  padding-bottom: 0px;
}
#cover-right-widget .crw-padding-left-0, #cover-right-widget .crw-pl-0 {
  padding-left: 0px;
}
#cover-right-widget .crw-padding-horizontal-0, #cover-right-widget .crw-ph-0 {
  padding-left: 0px;
  padding-right: 0px;
}
#cover-right-widget .crw-padding-vertical-0, #cover-right-widget .crw-pv-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
#cover-right-widget .crw-padding-5, #cover-right-widget .crw-p-5 {
  padding: 5px;
}
#cover-right-widget .crw-padding-top-5, #cover-right-widget .crw-pt-5 {
  padding-top: 5px;
}
#cover-right-widget .crw-padding-right-5, #cover-right-widget .crw-pr-5 {
  padding-right: 5px;
}
#cover-right-widget .crw-padding-bottom-5, #cover-right-widget .crw-pb-5 {
  padding-bottom: 5px;
}
#cover-right-widget .crw-padding-left-5, #cover-right-widget .crw-pl-5 {
  padding-left: 5px;
}
#cover-right-widget .crw-padding-horizontal-5, #cover-right-widget .crw-ph-5 {
  padding-left: 5px;
  padding-right: 5px;
}
#cover-right-widget .crw-padding-vertical-5, #cover-right-widget .crw-pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
#cover-right-widget .crw-padding-10, #cover-right-widget .crw-p-10 {
  padding: 10px;
}
#cover-right-widget .crw-padding-top-10, #cover-right-widget .crw-pt-10 {
  padding-top: 10px;
}
#cover-right-widget .crw-padding-right-10, #cover-right-widget .crw-pr-10 {
  padding-right: 10px;
}
#cover-right-widget .crw-padding-bottom-10, #cover-right-widget .crw-pb-10 {
  padding-bottom: 10px;
}
#cover-right-widget .crw-padding-left-10, #cover-right-widget .crw-pl-10 {
  padding-left: 10px;
}
#cover-right-widget .crw-padding-horizontal-10, #cover-right-widget .crw-ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}
#cover-right-widget .crw-padding-vertical-10, #cover-right-widget .crw-pv-10 {
  padding-top: 10px!important;
  padding-bottom: 10px!important;
}
#cover-right-widget .crw-padding-15, #cover-right-widget .crw-p-15 {
  padding: 15px;
}
#cover-right-widget .crw-padding-top-15, #cover-right-widget .crw-pt-15 {
  padding-top: 15px;
}
#cover-right-widget .crw-padding-right-15, #cover-right-widget .crw-pr-15 {
  padding-right: 15px;
}
#cover-right-widget .crw-padding-bottom-15, #cover-right-widget .crw-pb-15 {
  padding-bottom: 15px;
}
#cover-right-widget .crw-padding-left-15, #cover-right-widget .crw-pl-15 {
  padding-left: 15px!important;
}
#cover-right-widget .crw-padding-horizontal-15, #cover-right-widget .crw-ph-15 {
  padding-left: 15px;
  padding-right: 15px;
}
#cover-right-widget .crw-padding-vertical-15, #cover-right-widget .crw-pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
#cover-right-widget .crw-padding-20, #cover-right-widget .crw-p-20 {
  padding: 20px;
}
#cover-right-widget .crw-padding-top-20, #cover-right-widget .crw-pt-20 {
  padding-top: 20px;
}
#cover-right-widget .crw-padding-right-20, #cover-right-widget .crw-pr-20 {
  padding-right: 20px;
}
#cover-right-widget .crw-padding-bottom-20, #cover-right-widget .crw-pb-20 {
  padding-bottom: 20px;
}
#cover-right-widget .crw-padding-left-20, #cover-right-widget .crw-pl-20 {
  padding-left: 20px;
}
#cover-right-widget .crw-padding-horizontal-20, #cover-right-widget .crw-ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}
#cover-right-widget .crw-padding-vertical-20, #cover-right-widget .crw-pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
#cover-right-widget .crw-padding-25, #cover-right-widget .crw-p-25 {
  padding: 25px;
}
#cover-right-widget .crw-padding-top-25, #cover-right-widget .crw-pt-25 {
  padding-top: 25px;
}
#cover-right-widget .crw-padding-right-25, #cover-right-widget .crw-pr-25 {
  padding-right: 25px;
}
#cover-right-widget .crw-padding-bottom-25, #cover-right-widget .crw-pb-25 {
  padding-bottom: 25px;
}
#cover-right-widget .crw-padding-left-25, #cover-right-widget .crw-pl-25 {
  padding-left: 25px;
}
#cover-right-widget .crw-padding-horizontal-25, #cover-right-widget .crw-ph-25 {
  padding-left: 25px;
  padding-right: 25px;
}
#cover-right-widget .crw-padding-vertical-25, #cover-right-widget .crw-pv-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
#cover-right-widget .crw-padding-30, #cover-right-widget .crw-p-30 {
  padding: 30px;
}
#cover-right-widget .crw-padding-top-30, #cover-right-widget .crw-pt-30 {
  padding-top: 30px;
}
#cover-right-widget .crw-padding-right-30, #cover-right-widget .crw-pr-30 {
  padding-right: 30px;
}
#cover-right-widget .crw-padding-bottom-30, #cover-right-widget .crw-pb-30 {
  padding-bottom: 30px;
}
#cover-right-widget .crw-padding-left-30, #cover-right-widget .crw-pl-30 {
  padding-left: 30px;
}
#cover-right-widget .crw-padding-horizontal-30, #cover-right-widget .crw-ph-30 {
  padding-left: 30px;
  padding-right: 30px;
}
#cover-right-widget .crw-padding-vertical-30, #cover-right-widget .crw-pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
#cover-right-widget .crw-padding-35, #cover-right-widget .crw-p-35 {
  padding: 35px;
}
#cover-right-widget .crw-padding-top-35, #cover-right-widget .crw-pt-35 {
  padding-top: 35px;
}
#cover-right-widget .crw-padding-right-35, #cover-right-widget .crw-pr-35 {
  padding-right: 35px;
}
#cover-right-widget .crw-padding-bottom-35, #cover-right-widget .crw-pb-35 {
  padding-bottom: 35px;
}
#cover-right-widget .crw-padding-left-35, #cover-right-widget .crw-pl-35 {
  padding-left: 35px;
}
#cover-right-widget .crw-padding-horizontal-35, #cover-right-widget .crw-ph-35 {
  padding-left: 35px;
  padding-right: 35px;
}
#cover-right-widget .crw-padding-vertical-35, #cover-right-widget .crw-pv-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
#cover-right-widget .crw-padding-40, #cover-right-widget .crw-p-40 {
  padding: 40px;
}
#cover-right-widget .crw-padding-top-40, #cover-right-widget .crw-pt-40 {
  padding-top: 40px;
}
#cover-right-widget .crw-padding-right-40, #cover-right-widget .crw-pr-40 {
  padding-right: 40px;
}
#cover-right-widget .crw-padding-bottom-40, #cover-right-widget .crw-pb-40 {
  padding-bottom: 40px;
}
#cover-right-widget .crw-padding-left-40, #cover-right-widget .crw-pl-40 {
  padding-left: 40px;
}
#cover-right-widget .crw-padding-horizontal-40, #cover-right-widget .crw-ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}
#cover-right-widget .crw-padding-vertical-40, #cover-right-widget .crw-pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
#cover-right-widget .crw-padding-45, #cover-right-widget .crw-p-45 {
  padding: 45px;
}
#cover-right-widget .crw-padding-top-45, #cover-right-widget .crw-pt-45 {
  padding-top: 45px;
}
#cover-right-widget .crw-padding-right-45, #cover-right-widget .crw-pr-45 {
  padding-right: 45px;
}
#cover-right-widget .crw-padding-bottom-45, #cover-right-widget .crw-pb-45 {
  padding-bottom: 45px;
}
#cover-right-widget .crw-padding-left-45, #cover-right-widget .crw-pl-45 {
  padding-left: 45px;
}
#cover-right-widget .crw-padding-horizontal-45, #cover-right-widget .crw-ph-45 {
  padding-left: 45px;
  padding-right: 45px;
}
#cover-right-widget .crw-padding-vertical-45, #cover-right-widget .crw-pv-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
#cover-right-widget .crw-padding-50, #cover-right-widget .crw-p-50 {
  padding: 50px;
}
#cover-right-widget .crw-padding-top-50, #cover-right-widget .crw-pt-50 {
  padding-top: 50px;
}
#cover-right-widget .crw-padding-right-50, #cover-right-widget .crw-pr-50 {
  padding-right: 50px;
}
#cover-right-widget .crw-padding-bottom-50, #cover-right-widget .crw-pb-50 {
  padding-bottom: 50px;
}
#cover-right-widget .crw-padding-left-50, #cover-right-widget .crw-pl-50 {
  padding-left: 50px;
}
#cover-right-widget .crw-padding-horizontal-50, #cover-right-widget .crw-ph-50 {
  padding-left: 50px;
  padding-right: 50px;
}
#cover-right-widget .crw-padding-vertical-50, #cover-right-widget .crw-pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
#cover-right-widget .crw-padding-55, #cover-right-widget .crw-p-55 {
  padding: 55px;
}
#cover-right-widget .crw-padding-top-55, #cover-right-widget .crw-pt-55 {
  padding-top: 55px;
}
#cover-right-widget .crw-padding-right-55, #cover-right-widget .crw-pr-55 {
  padding-right: 55px;
}
#cover-right-widget .crw-padding-bottom-55, #cover-right-widget .crw-pb-55 {
  padding-bottom: 55px;
}
#cover-right-widget .crw-padding-left-55, #cover-right-widget .crw-pl-55 {
  padding-left: 55px;
}
#cover-right-widget .crw-padding-horizontal-55, #cover-right-widget .crw-ph-55 {
  padding-left: 55px;
  padding-right: 55px;
}
#cover-right-widget .crw-padding-vertical-55, #cover-right-widget .crw-pv-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}
#cover-right-widget .crw-padding-60, #cover-right-widget .crw-p-60 {
  padding: 60px;
}
#cover-right-widget .crw-padding-top-60, #cover-right-widget .crw-pt-60 {
  padding-top: 60px;
}
#cover-right-widget .crw-padding-right-60, #cover-right-widget .crw-pr-60 {
  padding-right: 60px;
}
#cover-right-widget .crw-padding-bottom-60, #cover-right-widget .crw-pb-60 {
  padding-bottom: 60px;
}
#cover-right-widget .crw-padding-left-60, #cover-right-widget .crw-pl-60 {
  padding-left: 60px;
}
#cover-right-widget .crw-padding-horizontal-60, #cover-right-widget .crw-ph-60 {
  padding-left: 60px;
  padding-right: 60px;
}
#cover-right-widget .crw-padding-vertical-60, #cover-right-widget .crw-pv-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
#cover-right-widget .crw-padding-65, #cover-right-widget .crw-p-65 {
  padding: 65px;
}
#cover-right-widget .crw-padding-top-65, #cover-right-widget .crw-pt-65 {
  padding-top: 65px;
}
#cover-right-widget .crw-padding-right-65, #cover-right-widget .crw-pr-65 {
  padding-right: 65px;
}
#cover-right-widget .crw-padding-bottom-65, #cover-right-widget .crw-pb-65 {
  padding-bottom: 65px;
}
#cover-right-widget .crw-padding-left-65, #cover-right-widget .crw-pl-65 {
  padding-left: 65px;
}
#cover-right-widget .crw-padding-horizontal-65, #cover-right-widget .crw-ph-65 {
  padding-left: 65px;
  padding-right: 65px;
}
#cover-right-widget .crw-padding-vertical-65, #cover-right-widget .crw-pv-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}
#cover-right-widget .crw-padding-70, #cover-right-widget .crw-p-70 {
  padding: 70px;
}
#cover-right-widget .crw-padding-top-70, #cover-right-widget .crw-pt-70 {
  padding-top: 70px;
}
#cover-right-widget .crw-padding-right-70, #cover-right-widget .crw-pr-70 {
  padding-right: 70px;
}
#cover-right-widget .crw-padding-bottom-70, #cover-right-widget .crw-pb-70 {
  padding-bottom: 70px;
}
#cover-right-widget .crw-padding-left-70, #cover-right-widget .crw-pl-70 {
  padding-left: 70px;
}
#cover-right-widget .crw-padding-horizontal-70, #cover-right-widget .crw-ph-70 {
  padding-left: 70px;
  padding-right: 70px;
}
#cover-right-widget .crw-padding-vertical-70, #cover-right-widget .crw-pv-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
#cover-right-widget .crw-padding-75, #cover-right-widget .crw-p-75 {
  padding: 75px;
}
#cover-right-widget .crw-padding-top-75, #cover-right-widget .crw-pt-75 {
  padding-top: 75px;
}
#cover-right-widget .crw-padding-right-75, #cover-right-widget .crw-pr-75 {
  padding-right: 75px;
}
#cover-right-widget .crw-padding-bottom-75, #cover-right-widget .crw-pb-75 {
  padding-bottom: 75px;
}
#cover-right-widget .crw-padding-left-75, #cover-right-widget .crw-pl-75 {
  padding-left: 75px;
}
#cover-right-widget .crw-padding-horizontal-75, #cover-right-widget .crw-ph-75 {
  padding-left: 75px;
  padding-right: 75px;
}
#cover-right-widget .crw-padding-vertical-75, #cover-right-widget .crw-pv-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}
#cover-right-widget .crw-padding-80, #cover-right-widget .crw-p-80 {
  padding: 80px;
}
#cover-right-widget .crw-padding-top-80, #cover-right-widget .crw-pt-80 {
  padding-top: 80px;
}
#cover-right-widget .crw-padding-right-80, #cover-right-widget .crw-pr-80 {
  padding-right: 80px;
}
#cover-right-widget .crw-padding-bottom-80, #cover-right-widget .crw-pb-80 {
  padding-bottom: 80px;
}
#cover-right-widget .crw-padding-left-80, #cover-right-widget .crw-pl-80 {
  padding-left: 80px;
}
#cover-right-widget .crw-padding-horizontal-80, #cover-right-widget .crw-ph-80 {
  padding-left: 80px;
  padding-right: 80px;
}
#cover-right-widget .crw-padding-vertical-80, #cover-right-widget .crw-pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
#cover-right-widget .crw-padding-85, #cover-right-widget .crw-p-85 {
  padding: 85px;
}
#cover-right-widget .crw-padding-top-85, #cover-right-widget .crw-pt-85 {
  padding-top: 85px;
}
#cover-right-widget .crw-padding-right-85, #cover-right-widget .crw-pr-85 {
  padding-right: 85px;
}
#cover-right-widget .crw-padding-bottom-85, #cover-right-widget .crw-pb-85 {
  padding-bottom: 85px;
}
#cover-right-widget .crw-padding-left-85, #cover-right-widget .crw-pl-85 {
  padding-left: 85px;
}
#cover-right-widget .crw-padding-horizontal-85, #cover-right-widget .crw-ph-85 {
  padding-left: 85px;
  padding-right: 85px;
}
#cover-right-widget .crw-padding-vertical-85, #cover-right-widget .crw-pv-85 {
  padding-top: 85px;
  padding-bottom: 85px;
}
#cover-right-widget .crw-padding-90, #cover-right-widget .crw-p-90 {
  padding: 90px;
}
#cover-right-widget .crw-padding-top-90, #cover-right-widget .crw-pt-90 {
  padding-top: 90px;
}
#cover-right-widget .crw-padding-right-90, #cover-right-widget .crw-pr-90 {
  padding-right: 90px;
}
#cover-right-widget .crw-padding-bottom-90, #cover-right-widget .crw-pb-90 {
  padding-bottom: 90px;
}
#cover-right-widget .crw-padding-left-90, #cover-right-widget .crw-pl-90 {
  padding-left: 90px;
}
#cover-right-widget .crw-padding-horizontal-90, #cover-right-widget .crw-ph-90 {
  padding-left: 90px;
  padding-right: 90px;
}
#cover-right-widget .crw-padding-vertical-90, #cover-right-widget .crw-pv-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}
#cover-right-widget .crw-padding-95, #cover-right-widget .crw-p-95 {
  padding: 95px;
}
#cover-right-widget .crw-padding-top-95, #cover-right-widget .crw-pt-95 {
  padding-top: 95px;
}
#cover-right-widget .crw-padding-right-95, #cover-right-widget .crw-pr-95 {
  padding-right: 95px;
}
#cover-right-widget .crw-padding-bottom-95, #cover-right-widget .crw-pb-95 {
  padding-bottom: 95px;
}
#cover-right-widget .crw-padding-left-95, #cover-right-widget .crw-pl-95 {
  padding-left: 95px;
}
#cover-right-widget .crw-padding-horizontal-95, #cover-right-widget .crw-ph-95 {
  padding-left: 95px;
  padding-right: 95px;
}
#cover-right-widget .crw-padding-vertical-95, #cover-right-widget .crw-pv-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}
#cover-right-widget .crw-padding-100, #cover-right-widget .crw-p-100 {
  padding: 100px;
}
#cover-right-widget .crw-padding-top-100, #cover-right-widget .crw-pt-100 {
  padding-top: 100px;
}
#cover-right-widget .crw-padding-right-100, #cover-right-widget .crw-pr-100 {
  padding-right: 100px;
}
#cover-right-widget .crw-padding-bottom-100, #cover-right-widget .crw-pb-100 {
  padding-bottom: 100px;
}
#cover-right-widget .crw-padding-left-100, #cover-right-widget .crw-pl-100 {
  padding-left: 100px;
}
#cover-right-widget .crw-padding-horizontal-100, #cover-right-widget .crw-ph-100 {
  padding-left: 100px;
  padding-right: 100px;
}
#cover-right-widget .crw-padding-vertical-100, #cover-right-widget .crw-pv-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
#cover-right-widget .crw-padding-4, #cover-right-widget .crw-p-4 {
  padding: 4px;
}
#cover-right-widget .crw-padding-top-4, #cover-right-widget .crw-pt-4 {
  padding-top: 4px;
}
#cover-right-widget .crw-padding-right-4, #cover-right-widget .crw-pr-4 {
  padding-right: 8px;
}
#cover-right-widget .crw-padding-bottom-4, #cover-right-widget .crw-pb-4 {
  padding-bottom: 4px;
}
#cover-right-widget .crw-padding-left-4, #cover-right-widget .crw-pl-4 {
  padding-left: 4px;
}
#cover-right-widget .crw-padding-horizontal-4, #cover-right-widget .crw-ph-4 {
  padding-left: 4px;
  padding-right: 4px;
}
#cover-right-widget .crw-padding-vertical-4, #cover-right-widget .crw-pv-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
#cover-right-widget .crw-padding-8, #cover-right-widget .crw-p-8 {
  padding: 8px;
}
#cover-right-widget .crw-padding-top-8, #cover-right-widget .crw-pt-8 {
  padding-top: 8px;
}
#cover-right-widget .crw-padding-right-8, #cover-right-widget .crw-pr-8 {
  padding-right: 16px;
}
#cover-right-widget .crw-padding-bottom-8, #cover-right-widget .crw-pb-8 {
  padding-bottom: 8px;
}
#cover-right-widget .crw-padding-left-8, #cover-right-widget .crw-pl-8 {
  padding-left: 8px;
}
#cover-right-widget .crw-padding-horizontal-8, #cover-right-widget .crw-ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}
#cover-right-widget .crw-padding-vertical-8, #cover-right-widget .crw-pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
#cover-right-widget .crw-padding-12, #cover-right-widget .crw-p-12 {
  padding: 12px;
}
#cover-right-widget .crw-padding-top-12, #cover-right-widget .crw-pt-12 {
  padding-top: 12px;
}
#cover-right-widget .crw-padding-right-12, #cover-right-widget .crw-pr-12 {
  padding-right: 24px;
}
#cover-right-widget .crw-padding-bottom-12, #cover-right-widget .crw-pb-12 {
  padding-bottom: 12px;
}
#cover-right-widget .crw-padding-left-12, #cover-right-widget .crw-pl-12 {
  padding-left: 12px;
}
#cover-right-widget .crw-padding-horizontal-12, #cover-right-widget .crw-ph-12 {
  padding-left: 12px;
  padding-right: 12px;
}
#cover-right-widget .crw-padding-vertical-12, #cover-right-widget .crw-pv-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
#cover-right-widget .crw-padding-16, #cover-right-widget .crw-p-16 {
  padding: 16px;
}
#cover-right-widget .crw-padding-top-16, #cover-right-widget .crw-pt-16 {
  padding-top: 16px;
}
#cover-right-widget .crw-padding-right-16, #cover-right-widget .crw-pr-16 {
  padding-right: 16px;
}
#cover-right-widget .crw-padding-bottom-16, #cover-right-widget .crw-pb-16 {
  padding-bottom: 16px;
}
#cover-right-widget .crw-padding-left-16, #cover-right-widget .crw-pl-16 {
  padding-left: 16px;
}
#cover-right-widget .crw-padding-horizontal-16, #cover-right-widget .crw-ph-16 {
  padding-left: 16px;
  padding-right: 16px;
}
#cover-right-widget .crw-padding-vertical-16, #cover-right-widget .crw-pv-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
#cover-right-widget .crw-padding-24, #cover-right-widget .crw-p-24 {
  padding: 24px;
}
#cover-right-widget .crw-padding-top-24, #cover-right-widget .crw-pt-24 {
  padding-top: 24px;
}
#cover-right-widget .crw-padding-right-24, #cover-right-widget .crw-pr-24 {
  padding-right: 24px;
}
#cover-right-widget .crw-padding-bottom-24, #cover-right-widget .crw-pb-24 {
  padding-bottom: 24px;
}
#cover-right-widget .crw-padding-left-24, #cover-right-widget .crw-pl-24 {
  padding-left: 24px;
}
#cover-right-widget .crw-padding-horizontal-24, #cover-right-widget .crw-ph-24 {
  padding-left: 24px;
  padding-right: 24px;
}
#cover-right-widget .crw-padding-vertical-24, #cover-right-widget .crw-pv-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
#cover-right-widget .crw-padding-28, #cover-right-widget .crw-p-28 {
  padding: 28px;
}
#cover-right-widget .crw-padding-top-28, #cover-right-widget .crw-pt-28 {
  padding-top: 28px;
}
#cover-right-widget .crw-padding-right-28, #cover-right-widget .crw-pr-28 {
  padding-right: 56px;
}
#cover-right-widget .crw-padding-bottom-28, #cover-right-widget .crw-pb-28 {
  padding-bottom: 28px;
}
#cover-right-widget .crw-padding-left-28, #cover-right-widget .crw-pl-28 {
  padding-left: 28px;
}
#cover-right-widget .crw-padding-horizontal-28, #cover-right-widget .crw-ph-28 {
  padding-left: 28px;
  padding-right: 28px;
}
#cover-right-widget .crw-padding-vertical-28, #cover-right-widget .crw-pv-28 {
  padding-top: 28px;
  padding-bottom: 28px;
}
#cover-right-widget .crw-padding-32, #cover-right-widget .crw-p-32 {
  padding: 32px;
}
#cover-right-widget .crw-padding-top-32, #cover-right-widget .crw-pt-32 {
  padding-top: 32px;
}
#cover-right-widget .crw-padding-right-32, #cover-right-widget .crw-pr-32 {
  padding-right: 64px;
}
#cover-right-widget .crw-padding-bottom-32, #cover-right-widget .crw-pb-32 {
  padding-bottom: 32px;
}
#cover-right-widget .crw-padding-left-32, #cover-right-widget .crw-pl-32 {
  padding-left: 32px;
}
#cover-right-widget .crw-padding-horizontal-32, #cover-right-widget .crw-ph-32 {
  padding-left: 32px;
  padding-right: 32px;
}
#cover-right-widget .crw-padding-vertical-32, #cover-right-widget .crw-pv-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
#cover-right-widget .crw-padding-36, #cover-right-widget .crw-p-36 {
  padding: 36px;
}
#cover-right-widget .crw-padding-top-36, #cover-right-widget .crw-pt-36 {
  padding-top: 36px;
}
#cover-right-widget .crw-padding-right-36, #cover-right-widget .crw-pr-36 {
  padding-right: 72px;
}
#cover-right-widget .crw-padding-bottom-36, #cover-right-widget .crw-pb-36 {
  padding-bottom: 36px;
}
#cover-right-widget .crw-padding-left-36, #cover-right-widget .crw-pl-36 {
  padding-left: 36px;
}
#cover-right-widget .crw-padding-horizontal-36, #cover-right-widget .crw-ph-36 {
  padding-left: 36px;
  padding-right: 36px;
}
#cover-right-widget .crw-padding-vertical-36, #cover-right-widget .crw-pv-36 {
  padding-top: 36px;
  padding-bottom: 36px;
}
#cover-right-widget .crw-padding-44, #cover-right-widget .crw-p-44 {
  padding: 44px;
}
#cover-right-widget .crw-padding-top-44, #cover-right-widget .crw-pt-44 {
  padding-top: 44px;
}
#cover-right-widget .crw-padding-right-44, #cover-right-widget .crw-pr-44 {
  padding-right: 88px;
}
#cover-right-widget .crw-padding-bottom-44, #cover-right-widget .crw-pb-44 {
  padding-bottom: 44px;
}
#cover-right-widget .crw-padding-left-44, #cover-right-widget .crw-pl-44 {
  padding-left: 44px;
}
#cover-right-widget .crw-padding-horizontal-44, #cover-right-widget .crw-ph-44 {
  padding-left: 44px;
  padding-right: 44px;
}
#cover-right-widget .crw-padding-vertical-44, #cover-right-widget .crw-pv-44 {
  padding-top: 44px;
  padding-bottom: 44px;
}
#cover-right-widget .crw-padding-48, #cover-right-widget .crw-p-48 {
  padding: 48px;
}
#cover-right-widget .crw-padding-top-48, #cover-right-widget .crw-pt-48 {
  padding-top: 48px;
}
#cover-right-widget .crw-padding-right-48, #cover-right-widget .crw-pr-48 {
  padding-right: 96px;
}
#cover-right-widget .crw-padding-bottom-48, #cover-right-widget .crw-pb-48 {
  padding-bottom: 48px;
}
#cover-right-widget .crw-padding-left-48, #cover-right-widget .crw-pl-48 {
  padding-left: 48px;
}
#cover-right-widget .crw-padding-horizontal-48, #cover-right-widget .crw-ph-48 {
  padding-left: 48px;
  padding-right: 48px;
}
#cover-right-widget .crw-padding-vertical-48, #cover-right-widget .crw-pv-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}
#cover-right-widget .crw-padding-52, #cover-right-widget .crw-p-52 {
  padding: 52px;
}
#cover-right-widget .crw-padding-top-52, #cover-right-widget .crw-pt-52 {
  padding-top: 52px;
}
#cover-right-widget .crw-padding-right-52, #cover-right-widget .crw-pr-52 {
  padding-right: 104px;
}
#cover-right-widget .crw-padding-bottom-52, #cover-right-widget .crw-pb-52 {
  padding-bottom: 52px;
}
#cover-right-widget .crw-padding-left-52, #cover-right-widget .crw-pl-52 {
  padding-left: 52px;
}
#cover-right-widget .crw-padding-horizontal-52, #cover-right-widget .crw-ph-52 {
  padding-left: 52px;
  padding-right: 52px;
}
#cover-right-widget .crw-padding-vertical-52, #cover-right-widget .crw-pv-52 {
  padding-top: 52px;
  padding-bottom: 52px;
}
#cover-right-widget .crw-padding-56, #cover-right-widget .crw-p-56 {
  padding: 56px;
}
#cover-right-widget .crw-padding-top-56, #cover-right-widget .crw-pt-56 {
  padding-top: 56px;
}
#cover-right-widget .crw-padding-right-56, #cover-right-widget .crw-pr-56 {
  padding-right: 112px;
}
#cover-right-widget .crw-padding-bottom-56, #cover-right-widget .crw-pb-56 {
  padding-bottom: 56px;
}
#cover-right-widget .crw-padding-left-56, #cover-right-widget .crw-pl-56 {
  padding-left: 56px;
}
#cover-right-widget .crw-padding-horizontal-56, #cover-right-widget .crw-ph-56 {
  padding-left: 56px;
  padding-right: 56px;
}
#cover-right-widget .crw-padding-vertical-56, #cover-right-widget .crw-pv-56 {
  padding-top: 56px;
  padding-bottom: 56px;
}
#cover-right-widget .crw-padding-64, #cover-right-widget .crw-p-64 {
  padding: 64px;
}
#cover-right-widget .crw-padding-top-64, #cover-right-widget .crw-pt-64 {
  padding-top: 64px;
}
#cover-right-widget .crw-padding-right-64, #cover-right-widget .crw-pr-64 {
  padding-right: 128px;
}
#cover-right-widget .crw-padding-bottom-64, #cover-right-widget .crw-pb-64 {
  padding-bottom: 64px;
}
#cover-right-widget .crw-padding-left-64, #cover-right-widget .crw-pl-64 {
  padding-left: 64px;
}
#cover-right-widget .crw-padding-horizontal-64, #cover-right-widget .crw-ph-64 {
  padding-left: 64px;
  padding-right: 64px;
}
#cover-right-widget .crw-padding-vertical-64, #cover-right-widget .crw-pv-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}
#cover-right-widget .crw-padding-68, #cover-right-widget .crw-p-68 {
  padding: 68px;
}
#cover-right-widget .crw-padding-top-68, #cover-right-widget .crw-pt-68 {
  padding-top: 68px;
}
#cover-right-widget .crw-padding-right-68, #cover-right-widget .crw-pr-68 {
  padding-right: 136px;
}
#cover-right-widget .crw-padding-bottom-68, #cover-right-widget .crw-pb-68 {
  padding-bottom: 68px;
}
#cover-right-widget .crw-padding-left-68, #cover-right-widget .crw-pl-68 {
  padding-left: 68px;
}
#cover-right-widget .crw-padding-horizontal-68, #cover-right-widget .crw-ph-68 {
  padding-left: 68px;
  padding-right: 68px;
}
#cover-right-widget .crw-padding-vertical-68, #cover-right-widget .crw-pv-68 {
  padding-top: 68px;
  padding-bottom: 68px;
}
#cover-right-widget .crw-padding-72, #cover-right-widget .crw-p-72 {
  padding: 72px;
}
#cover-right-widget .crw-padding-top-72, #cover-right-widget .crw-pt-72 {
  padding-top: 72px;
}
#cover-right-widget .crw-padding-right-72, #cover-right-widget .crw-pr-72 {
  padding-right: 144px;
}
#cover-right-widget .crw-padding-bottom-72, #cover-right-widget .crw-pb-72 {
  padding-bottom: 72px;
}
#cover-right-widget .crw-padding-left-72, #cover-right-widget .crw-pl-72 {
  padding-left: 72px;
}
#cover-right-widget .crw-padding-horizontal-72, #cover-right-widget .crw-ph-72 {
  padding-left: 72px;
  padding-right: 72px;
}
#cover-right-widget .crw-padding-vertical-72, #cover-right-widget .crw-pv-72 {
  padding-top: 72px;
  padding-bottom: 72px;
}
#cover-right-widget .crw-padding-76, #cover-right-widget .crw-p-76 {
  padding: 76px;
}
#cover-right-widget .crw-padding-top-76, #cover-right-widget .crw-pt-76 {
  padding-top: 76px;
}
#cover-right-widget .crw-padding-right-76, #cover-right-widget .crw-pr-76 {
  padding-right: 152px;
}
#cover-right-widget .crw-padding-bottom-76, #cover-right-widget .crw-pb-76 {
  padding-bottom: 76px;
}
#cover-right-widget .crw-padding-left-76, #cover-right-widget .crw-pl-76 {
  padding-left: 76px;
}
#cover-right-widget .crw-padding-horizontal-76, #cover-right-widget .crw-ph-76 {
  padding-left: 76px;
  padding-right: 76px;
}
#cover-right-widget .crw-padding-vertical-76, #cover-right-widget .crw-pv-76 {
  padding-top: 76px;
  padding-bottom: 76px;
}
#cover-right-widget .crw-padding-84, #cover-right-widget .crw-p-84 {
  padding: 84px;
}
#cover-right-widget .crw-padding-top-84, #cover-right-widget .crw-pt-84 {
  padding-top: 84px;
}
#cover-right-widget .crw-padding-right-84, #cover-right-widget .crw-pr-84 {
  padding-right: 168px;
}
#cover-right-widget .crw-padding-bottom-84, #cover-right-widget .crw-pb-84 {
  padding-bottom: 84px;
}
#cover-right-widget .crw-padding-left-84, #cover-right-widget .crw-pl-84 {
  padding-left: 84px;
}
#cover-right-widget .crw-padding-horizontal-84, #cover-right-widget .crw-ph-84 {
  padding-left: 84px;
  padding-right: 84px;
}
#cover-right-widget .crw-padding-vertical-84, #cover-right-widget .crw-pv-84 {
  padding-top: 84px;
  padding-bottom: 84px;
}
#cover-right-widget .crw-padding-88, #cover-right-widget .crw-p-88 {
  padding: 88px;
}
#cover-right-widget .crw-padding-top-88, #cover-right-widget .crw-pt-88 {
  padding-top: 88px;
}
#cover-right-widget .crw-padding-right-88, #cover-right-widget .crw-pr-88 {
  padding-right: 176px;
}
#cover-right-widget .crw-padding-bottom-88, #cover-right-widget .crw-pb-88 {
  padding-bottom: 88px;
}
#cover-right-widget .crw-padding-left-88, #cover-right-widget .crw-pl-88 {
  padding-left: 88px;
}
#cover-right-widget .crw-padding-horizontal-88, #cover-right-widget .crw-ph-88 {
  padding-left: 88px;
  padding-right: 88px;
}
#cover-right-widget .crw-padding-vertical-88, #cover-right-widget .crw-pv-88 {
  padding-top: 88px;
  padding-bottom: 88px;
}
#cover-right-widget .crw-padding-92, #cover-right-widget .crw-p-92 {
  padding: 92px;
}
#cover-right-widget .crw-padding-top-92, #cover-right-widget .crw-pt-92 {
  padding-top: 92px;
}
#cover-right-widget .crw-padding-right-92, #cover-right-widget .crw-pr-92 {
  padding-right: 184px;
}
#cover-right-widget .crw-padding-bottom-92, #cover-right-widget .crw-pb-92 {
  padding-bottom: 92px;
}
#cover-right-widget .crw-padding-left-92, #cover-right-widget .crw-pl-92 {
  padding-left: 92px;
}
#cover-right-widget .crw-padding-horizontal-92, #cover-right-widget .crw-ph-92 {
  padding-left: 92px;
  padding-right: 92px;
}
#cover-right-widget .crw-padding-vertical-92, #cover-right-widget .crw-pv-92 {
  padding-top: 92px;
  padding-bottom: 92px;
}
#cover-right-widget .crw-padding-96, #cover-right-widget .crw-p-96 {
  padding: 96px;
}
#cover-right-widget .crw-padding-top-96, #cover-right-widget .crw-pt-96 {
  padding-top: 96px;
}
#cover-right-widget .crw-padding-right-96, #cover-right-widget .crw-pr-96 {
  padding-right: 192px;
}
#cover-right-widget .crw-padding-bottom-96, #cover-right-widget .crw-pb-96 {
  padding-bottom: 96px;
}
#cover-right-widget .crw-padding-left-96, #cover-right-widget .crw-pl-96 {
  padding-left: 96px;
}
#cover-right-widget .crw-padding-horizontal-96, #cover-right-widget .crw-ph-96 {
  padding-left: 96px;
  padding-right: 96px;
}
#cover-right-widget .crw-padding-vertical-96, #cover-right-widget .crw-pv-96 {
  padding-top: 96px;
  padding-bottom: 96px;
}
#cover-right-widget .crw-margin-0, #cover-right-widget .crw-m-0 {
  margin: 0px !important;
}
#cover-right-widget .crw-margin-top-0, #cover-right-widget .crw-mt-0 {
  margin-top: 0px !important;
}
#cover-right-widget .crw-margin-right-0, #cover-right-widget .crw-mr-0 {
  margin-right: 0px !important;
}
#cover-right-widget .crw-margin-bottom-0, #cover-right-widget .crw-mb-0 {
  margin-bottom: 0px!important;
}
#cover-right-widget .crw-margin-left-0, #cover-right-widget .crw-ml-0 {
  margin-left: 0px !important;
}
#cover-right-widget .crw-margin-horizontal-0, #cover-right-widget .crw-mh-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
#cover-right-widget .crw-margin-vertical-0, #cover-right-widget .crw-mv-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
#cover-right-widget .crw-margin-5, #cover-right-widget .crw-m-5 {
  margin: 5px !important;
}
#cover-right-widget .crw-margin-top-5, #cover-right-widget .crw-mt-5 {
  margin-top: 5px !important;
}
#cover-right-widget .crw-margin-right-5, #cover-right-widget .crw-mr-5 {
  margin-right: 5px !important;
}
#cover-right-widget .crw-margin-bottom-5, #cover-right-widget .crw-mb-5 {
  margin-bottom: 5px !important;
}
#cover-right-widget .crw-margin-left-5, #cover-right-widget .crw-ml-5 {
  margin-left: 5px !important;
}
#cover-right-widget .crw-margin-horizontal-5, #cover-right-widget .crw-mh-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
#cover-right-widget .crw-margin-vertical-5, #cover-right-widget .crw-mv-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
#cover-right-widget .crw-margin-10, #cover-right-widget .crw-m-10 {
  margin: 10px !important;
}
#cover-right-widget .crw-margin-top-10, #cover-right-widget .crw-mt-10 {
  margin-top: 10px !important;
}
#cover-right-widget .crw-margin-right-10, #cover-right-widget .crw-mr-10 {
  margin-right: 10px !important;
}
#cover-right-widget .crw-margin-bottom-10, #cover-right-widget .crw-mb-10 {
  margin-bottom: 10px !important;
}
#cover-right-widget .crw-margin-left-10, #cover-right-widget .crw-ml-10 {
  margin-left: 10px !important;
}
#cover-right-widget .crw-margin-horizontal-10, #cover-right-widget .crw-mh-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
#cover-right-widget .crw-margin-vertical-10, #cover-right-widget .crw-mv-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
#cover-right-widget .crw-margin-15, #cover-right-widget .crw-m-15 {
  margin: 15px !important;
}
#cover-right-widget .crw-margin-top-15, #cover-right-widget .crw-mt-15 {
  margin-top: 15px !important;
}
#cover-right-widget .crw-margin-right-15, #cover-right-widget .crw-mr-15 {
  margin-right: 15px !important;
}
#cover-right-widget .crw-margin-bottom-15, #cover-right-widget .crw-mb-15 {
  margin-bottom: 15px !important;
}
#cover-right-widget .crw-margin-left-15, #cover-right-widget .crw-ml-15 {
  margin-left: 15px !important;
}
#cover-right-widget .crw-margin-horizontal-15, #cover-right-widget .crw-mh-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
#cover-right-widget .crw-margin-vertical-15, #cover-right-widget .crw-mv-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
#cover-right-widget .crw-margin-20, #cover-right-widget .crw-m-20 {
  margin: 20px !important;
}
#cover-right-widget .crw-margin-top-20, #cover-right-widget .crw-mt-20 {
  margin-top: 20px !important;
}
#cover-right-widget .crw-margin-right-20, #cover-right-widget .crw-mr-20 {
  margin-right: 20px !important;
}
#cover-right-widget .crw-margin-bottom-20, #cover-right-widget .crw-mb-20 {
  margin-bottom: 20px !important;
}
#cover-right-widget .crw-margin-left-20, #cover-right-widget .crw-ml-20 {
  margin-left: 20px !important;
}
#cover-right-widget .crw-margin-horizontal-20, #cover-right-widget .crw-mh-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
#cover-right-widget .crw-margin-vertical-20, #cover-right-widget .crw-mv-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
#cover-right-widget .crw-margin-25, #cover-right-widget .crw-m-25 {
  margin: 25px !important;
}
#cover-right-widget .crw-margin-top-25, #cover-right-widget .crw-mt-25 {
  margin-top: 25px !important;
}
#cover-right-widget .crw-margin-right-25, #cover-right-widget .crw-mr-25 {
  margin-right: 25px !important;
}
#cover-right-widget .crw-margin-bottom-25, #cover-right-widget .crw-mb-25 {
  margin-bottom: 25px !important;
}
#cover-right-widget .crw-margin-left-25, #cover-right-widget .crw-ml-25 {
  margin-left: 25px !important;
}
#cover-right-widget .crw-margin-horizontal-25, #cover-right-widget .crw-mh-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}
#cover-right-widget .crw-margin-vertical-25, #cover-right-widget .crw-mv-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
#cover-right-widget .crw-margin-30, #cover-right-widget .crw-m-30 {
  margin: 30px !important;
}
#cover-right-widget .crw-margin-top-30, #cover-right-widget .crw-mt-30 {
  margin-top: 30px !important;
}
#cover-right-widget .crw-margin-right-30, #cover-right-widget .crw-mr-30 {
  margin-right: 30px !important;
}
#cover-right-widget .crw-margin-bottom-30, #cover-right-widget .crw-mb-30 {
  margin-bottom: 30px !important;
}
#cover-right-widget .crw-margin-left-30, #cover-right-widget .crw-ml-30 {
  margin-left: 30px !important;
}
#cover-right-widget .crw-margin-horizontal-30, #cover-right-widget .crw-mh-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
#cover-right-widget .crw-margin-vertical-30, #cover-right-widget .crw-mv-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
#cover-right-widget .crw-margin-35, #cover-right-widget .crw-m-35 {
  margin: 35px !important;
}
#cover-right-widget .crw-margin-top-35, #cover-right-widget .crw-mt-35 {
  margin-top: 35px !important;
}
#cover-right-widget .crw-margin-right-35, #cover-right-widget .crw-mr-35 {
  margin-right: 35px !important;
}
#cover-right-widget .crw-margin-bottom-35, #cover-right-widget .crw-mb-35 {
  margin-bottom: 35px !important;
}
#cover-right-widget .crw-margin-left-35, #cover-right-widget .crw-ml-35 {
  margin-left: 35px !important;
}
#cover-right-widget .crw-margin-horizontal-35, #cover-right-widget .crw-mh-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}
#cover-right-widget .crw-margin-vertical-35, #cover-right-widget .crw-mv-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}
#cover-right-widget .crw-margin-40, #cover-right-widget .crw-m-40 {
  margin: 40px !important;
}
#cover-right-widget .crw-margin-top-40, #cover-right-widget .crw-mt-40 {
  margin-top: 40px !important;
}
#cover-right-widget .crw-margin-right-40, #cover-right-widget .crw-mr-40 {
  margin-right: 40px !important;
}
#cover-right-widget .crw-margin-bottom-40, #cover-right-widget .crw-mb-40 {
  margin-bottom: 40px !important;
}
#cover-right-widget .crw-margin-left-40, #cover-right-widget .crw-ml-40 {
  margin-left: 40px !important;
}
#cover-right-widget .crw-margin-horizontal-40, #cover-right-widget .crw-mh-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
#cover-right-widget .crw-margin-vertical-40, #cover-right-widget .crw-mv-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
#cover-right-widget .crw-margin-45, #cover-right-widget .crw-m-45 {
  margin: 45px !important;
}
#cover-right-widget .crw-margin-top-45, #cover-right-widget .crw-mt-45 {
  margin-top: 45px !important;
}
#cover-right-widget .crw-margin-right-45, #cover-right-widget .crw-mr-45 {
  margin-right: 45px !important;
}
#cover-right-widget .crw-margin-bottom-45, #cover-right-widget .crw-mb-45 {
  margin-bottom: 45px !important;
}
#cover-right-widget .crw-margin-left-45, #cover-right-widget .crw-ml-45 {
  margin-left: 45px !important;
}
#cover-right-widget .crw-margin-horizontal-45, #cover-right-widget .crw-mh-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}
#cover-right-widget .crw-margin-vertical-45, #cover-right-widget .crw-mv-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}
#cover-right-widget .crw-margin-50, #cover-right-widget .crw-m-50 {
  margin: 50px !important;
}
#cover-right-widget .crw-margin-top-50, #cover-right-widget .crw-mt-50 {
  margin-top: 50px !important;
}
#cover-right-widget .crw-margin-right-50, #cover-right-widget .crw-mr-50 {
  margin-right: 50px !important;
}
#cover-right-widget .crw-margin-bottom-50, #cover-right-widget .crw-mb-50 {
  margin-bottom: 50px !important;
}
#cover-right-widget .crw-margin-left-50, #cover-right-widget .crw-ml-50 {
  margin-left: 50px !important;
}
#cover-right-widget .crw-margin-horizontal-50, #cover-right-widget .crw-mh-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}
#cover-right-widget .crw-margin-vertical-50, #cover-right-widget .crw-mv-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
#cover-right-widget .crw-margin-55, #cover-right-widget .crw-m-55 {
  margin: 55px !important;
}
#cover-right-widget .crw-margin-top-55, #cover-right-widget .crw-mt-55 {
  margin-top: 55px !important;
}
#cover-right-widget .crw-margin-right-55, #cover-right-widget .crw-mr-55 {
  margin-right: 55px !important;
}
#cover-right-widget .crw-margin-bottom-55, #cover-right-widget .crw-mb-55 {
  margin-bottom: 55px !important;
}
#cover-right-widget .crw-margin-left-55, #cover-right-widget .crw-ml-55 {
  margin-left: 55px !important;
}
#cover-right-widget .crw-margin-horizontal-55, #cover-right-widget .crw-mh-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}
#cover-right-widget .crw-margin-vertical-55, #cover-right-widget .crw-mv-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}
#cover-right-widget .crw-margin-60, #cover-right-widget .crw-m-60 {
  margin: 60px !important;
}
#cover-right-widget .crw-margin-top-60, #cover-right-widget .crw-mt-60 {
  margin-top: 60px !important;
}
#cover-right-widget .crw-margin-right-60, #cover-right-widget .crw-mr-60 {
  margin-right: 60px !important;
}
#cover-right-widget .crw-margin-bottom-60, #cover-right-widget .crw-mb-60 {
  margin-bottom: 60px !important;
}
#cover-right-widget .crw-margin-left-60, #cover-right-widget .crw-ml-60 {
  margin-left: 60px !important;
}
#cover-right-widget .crw-margin-horizontal-60, #cover-right-widget .crw-mh-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}
#cover-right-widget .crw-margin-vertical-60, #cover-right-widget .crw-mv-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
#cover-right-widget .crw-margin-65, #cover-right-widget .crw-m-65 {
  margin: 65px !important;
}
#cover-right-widget .crw-margin-top-65, #cover-right-widget .crw-mt-65 {
  margin-top: 65px !important;
}
#cover-right-widget .crw-margin-right-65, #cover-right-widget .crw-mr-65 {
  margin-right: 65px !important;
}
#cover-right-widget .crw-margin-bottom-65, #cover-right-widget .crw-mb-65 {
  margin-bottom: 65px !important;
}
#cover-right-widget .crw-margin-left-65, #cover-right-widget .crw-ml-65 {
  margin-left: 65px !important;
}
#cover-right-widget .crw-margin-horizontal-65, #cover-right-widget .crw-mh-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}
#cover-right-widget .crw-margin-vertical-65, #cover-right-widget .crw-mv-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}
#cover-right-widget .crw-margin-70, #cover-right-widget .crw-m-70 {
  margin: 70px !important;
}
#cover-right-widget .crw-margin-top-70, #cover-right-widget .crw-mt-70 {
  margin-top: 70px !important;
}
#cover-right-widget .crw-margin-right-70, #cover-right-widget .crw-mr-70 {
  margin-right: 70px !important;
}
#cover-right-widget .crw-margin-bottom-70, #cover-right-widget .crw-mb-70 {
  margin-bottom: 70px !important;
}
#cover-right-widget .crw-margin-left-70, #cover-right-widget .crw-ml-70 {
  margin-left: 70px !important;
}
#cover-right-widget .crw-margin-horizontal-70, #cover-right-widget .crw-mh-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}
#cover-right-widget .crw-margin-vertical-70, #cover-right-widget .crw-mv-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
#cover-right-widget .crw-margin-75, #cover-right-widget .crw-m-75 {
  margin: 75px !important;
}
#cover-right-widget .crw-margin-top-75, #cover-right-widget .crw-mt-75 {
  margin-top: 75px !important;
}
#cover-right-widget .crw-margin-right-75, #cover-right-widget .crw-mr-75 {
  margin-right: 75px !important;
}
#cover-right-widget .crw-margin-bottom-75, #cover-right-widget .crw-mb-75 {
  margin-bottom: 75px !important;
}
#cover-right-widget .crw-margin-left-75, #cover-right-widget .crw-ml-75 {
  margin-left: 75px !important;
}
#cover-right-widget .crw-margin-horizontal-75, #cover-right-widget .crw-mh-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}
#cover-right-widget .crw-margin-vertical-75, #cover-right-widget .crw-mv-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}
#cover-right-widget .crw-margin-80, #cover-right-widget .crw-m-80 {
  margin: 80px !important;
}
#cover-right-widget .crw-margin-top-80, #cover-right-widget .crw-mt-80 {
  margin-top: 80px !important;
}
#cover-right-widget .crw-margin-right-80, #cover-right-widget .crw-mr-80 {
  margin-right: 80px !important;
}
#cover-right-widget .crw-margin-bottom-80, #cover-right-widget .crw-mb-80 {
  margin-bottom: 80px !important;
}
#cover-right-widget .crw-margin-left-80, #cover-right-widget .crw-ml-80 {
  margin-left: 80px !important;
}
#cover-right-widget .crw-margin-horizontal-80, #cover-right-widget .crw-mh-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}
#cover-right-widget .crw-margin-vertical-80, #cover-right-widget .crw-mv-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
#cover-right-widget .crw-margin-85, #cover-right-widget .crw-m-85 {
  margin: 85px !important;
}
#cover-right-widget .crw-margin-top-85, #cover-right-widget .crw-mt-85 {
  margin-top: 85px !important;
}
#cover-right-widget .crw-margin-right-85, #cover-right-widget .crw-mr-85 {
  margin-right: 85px !important;
}
#cover-right-widget .crw-margin-bottom-85, #cover-right-widget .crw-mb-85 {
  margin-bottom: 85px !important;
}
#cover-right-widget .crw-margin-left-85, #cover-right-widget .crw-ml-85 {
  margin-left: 85px !important;
}
#cover-right-widget .crw-margin-horizontal-85, #cover-right-widget .crw-mh-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}
#cover-right-widget .crw-margin-vertical-85, #cover-right-widget .crw-mv-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}
#cover-right-widget .crw-margin-90, #cover-right-widget .crw-m-90 {
  margin: 90px !important;
}
#cover-right-widget .crw-margin-top-90, #cover-right-widget .crw-mt-90 {
  margin-top: 90px !important;
}
#cover-right-widget .crw-margin-right-90, #cover-right-widget .crw-mr-90 {
  margin-right: 90px !important;
}
#cover-right-widget .crw-margin-bottom-90, #cover-right-widget .crw-mb-90 {
  margin-bottom: 90px !important;
}
#cover-right-widget .crw-margin-left-90, #cover-right-widget .crw-ml-90 {
  margin-left: 90px !important;
}
#cover-right-widget .crw-margin-horizontal-90, #cover-right-widget .crw-mh-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}
#cover-right-widget .crw-margin-vertical-90, #cover-right-widget .crw-mv-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}
#cover-right-widget .crw-margin-95, #cover-right-widget .crw-m-95 {
  margin: 95px !important;
}
#cover-right-widget .crw-margin-top-95, #cover-right-widget .crw-mt-95 {
  margin-top: 95px !important;
}
#cover-right-widget .crw-margin-right-95, #cover-right-widget .crw-mr-95 {
  margin-right: 95px !important;
}
#cover-right-widget .crw-margin-bottom-95, #cover-right-widget .crw-mb-95 {
  margin-bottom: 95px !important;
}
#cover-right-widget .crw-margin-left-95, #cover-right-widget .crw-ml-95 {
  margin-left: 95px !important;
}
#cover-right-widget .crw-margin-horizontal-95, #cover-right-widget .crw-mh-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}
#cover-right-widget .crw-margin-vertical-95, #cover-right-widget .crw-mv-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}
#cover-right-widget .crw-margin-100, #cover-right-widget .crw-m-100 {
  margin: 100px !important;
}
#cover-right-widget .crw-margin-top-100, #cover-right-widget .crw-mt-100 {
  margin-top: 100px !important;
}
#cover-right-widget .crw-margin-right-100, #cover-right-widget .crw-mr-100 {
  margin-right: 100px !important;
}
#cover-right-widget .crw-margin-bottom-100, #cover-right-widget .crw-mb-100 {
  margin-bottom: 100px !important;
}
#cover-right-widget .crw-margin-left-100, #cover-right-widget .crw-ml-100 {
  margin-left: 100px !important;
}
#cover-right-widget .crw-margin-horizontal-100, #cover-right-widget .crw-mh-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}
#cover-right-widget .crw-margin-vertical-100, #cover-right-widget .crw-mv-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}
#cover-right-widget .crw-margin-4, #cover-right-widget .crw-m-4 {
  margin: 4px !important;
}
#cover-right-widget .crw-margin-top-4, #cover-right-widget .crw-mt-4 {
  margin-top: 4px !important;
}
#cover-right-widget .crw-margin-right-4, #cover-right-widget .crw-mr-4 {
  margin-right: 4px !important;
}
#cover-right-widget .crw-margin-bottom-4, #cover-right-widget .crw-mb-4 {
  margin-bottom: 4px !important;
}
#cover-right-widget .crw-margin-left-4, #cover-right-widget .crw-ml-4 {
  margin-left: 4px !important;
}
#cover-right-widget .crw-margin-horizontal-4, #cover-right-widget .crw-mh-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
#cover-right-widget .crw-margin-vertical-4, #cover-right-widget .crw-mv-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
#cover-right-widget .crw-margin-8, #cover-right-widget .crw-m-8 {
  margin: 8px !important;
}
#cover-right-widget .crw-margin-top-8, #cover-right-widget .crw-mt-8 {
  margin-top: 8px !important;
}
#cover-right-widget .crw-margin-right-8, #cover-right-widget .crw-mr-8 {
  margin-right: 8px !important;
}
#cover-right-widget .crw-margin-bottom-8, #cover-right-widget .crw-mb-8 {
  margin-bottom: 8px !important;
}
#cover-right-widget .crw-margin-left-8, #cover-right-widget .crw-ml-8 {
  margin-left: 8px !important;
}
#cover-right-widget .crw-margin-horizontal-8, #cover-right-widget .crw-mh-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
#cover-right-widget .crw-margin-vertical-8, #cover-right-widget .crw-mv-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
#cover-right-widget .crw-margin-12, #cover-right-widget .crw-m-12 {
  margin: 12px !important;
}
#cover-right-widget .crw-margin-top-12, #cover-right-widget .crw-mt-12 {
  margin-top: 12px !important;
}
#cover-right-widget .crw-margin-right-12, #cover-right-widget .crw-mr-12 {
  margin-right: 12px !important;
}
#cover-right-widget .crw-margin-bottom-12, #cover-right-widget .crw-mb-12 {
  margin-bottom: 12px !important;
}
#cover-right-widget .crw-margin-left-12, #cover-right-widget .crw-ml-12 {
  margin-left: 12px !important;
}
#cover-right-widget .crw-margin-horizontal-12, #cover-right-widget .crw-mh-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
#cover-right-widget .crw-margin-vertical-12, #cover-right-widget .crw-mv-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
#cover-right-widget .crw-margin-16, #cover-right-widget .crw-m-16 {
  margin: 16px !important;
}
#cover-right-widget .crw-margin-top-16, #cover-right-widget .crw-mt-16 {
  margin-top: 16px !important;
}
#cover-right-widget .crw-margin-right-16, #cover-right-widget .crw-mr-16 {
  margin-right: 16px !important;
}
#cover-right-widget .crw-margin-bottom-16, #cover-right-widget .crw-mb-16 {
  margin-bottom: 16px !important;
}
#cover-right-widget .crw-margin-left-16, #cover-right-widget .crw-ml-16 {
  margin-left: 16px !important;
}
#cover-right-widget .crw-margin-horizontal-16, #cover-right-widget .crw-mh-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
#cover-right-widget .crw-margin-vertical-16, #cover-right-widget .crw-mv-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
#cover-right-widget .crw-margin-24, #cover-right-widget .crw-m-24 {
  margin: 24px !important;
}
#cover-right-widget .crw-margin-top-24, #cover-right-widget .crw-mt-24 {
  margin-top: 24px !important;
}
#cover-right-widget .crw-margin-right-24, #cover-right-widget .crw-mr-24 {
  margin-right: 24px !important;
}
#cover-right-widget .crw-margin-bottom-24, #cover-right-widget .crw-mb-24 {
  margin-bottom: 24px !important;
}
#cover-right-widget .crw-margin-left-24, #cover-right-widget .crw-ml-24 {
  margin-left: 24px !important;
}
#cover-right-widget .crw-margin-horizontal-24, #cover-right-widget .crw-mh-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
#cover-right-widget .crw-margin-vertical-24, #cover-right-widget .crw-mv-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
#cover-right-widget .crw-margin-28, #cover-right-widget .crw-m-28 {
  margin: 28px !important;
}
#cover-right-widget .crw-margin-top-28, #cover-right-widget .crw-mt-28 {
  margin-top: 28px !important;
}
#cover-right-widget .crw-margin-right-28, #cover-right-widget .crw-mr-28 {
  margin-right: 28px !important;
}
#cover-right-widget .crw-margin-bottom-28, #cover-right-widget .crw-mb-28 {
  margin-bottom: 28px !important;
}
#cover-right-widget .crw-margin-left-28, #cover-right-widget .crw-ml-28 {
  margin-left: 28px !important;
}
#cover-right-widget .crw-margin-horizontal-28, #cover-right-widget .crw-mh-28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}
#cover-right-widget .crw-margin-vertical-28, #cover-right-widget .crw-mv-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}
#cover-right-widget .crw-margin-32, #cover-right-widget .crw-m-32 {
  margin: 32px !important;
}
#cover-right-widget .crw-margin-top-32, #cover-right-widget .crw-mt-32 {
  margin-top: 32px !important;
}
#cover-right-widget .crw-margin-right-32, #cover-right-widget .crw-mr-32 {
  margin-right: 32px !important;
}
#cover-right-widget .crw-margin-bottom-32, #cover-right-widget .crw-mb-32 {
  margin-bottom: 32px !important;
}
#cover-right-widget .crw-margin-left-32, #cover-right-widget .crw-ml-32 {
  margin-left: 32px !important;
}
#cover-right-widget .crw-margin-horizontal-32, #cover-right-widget .crw-mh-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}
#cover-right-widget .crw-margin-vertical-32, #cover-right-widget .crw-mv-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
#cover-right-widget .crw-margin-36, #cover-right-widget .crw-m-36 {
  margin: 36px !important;
}
#cover-right-widget .crw-margin-top-36, #cover-right-widget .crw-mt-36 {
  margin-top: 36px !important;
}
#cover-right-widget .crw-margin-right-36, #cover-right-widget .crw-mr-36 {
  margin-right: 36px !important;
}
#cover-right-widget .crw-margin-bottom-36, #cover-right-widget .crw-mb-36 {
  margin-bottom: 36px !important;
}
#cover-right-widget .crw-margin-left-36, #cover-right-widget .crw-ml-36 {
  margin-left: 36px !important;
}
#cover-right-widget .crw-margin-horizontal-36, #cover-right-widget .crw-mh-36 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}
#cover-right-widget .crw-margin-vertical-36, #cover-right-widget .crw-mv-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}
#cover-right-widget .crw-margin-44, #cover-right-widget .crw-m-44 {
  margin: 44px !important;
}
#cover-right-widget .crw-margin-top-44, #cover-right-widget .crw-mt-44 {
  margin-top: 44px !important;
}
#cover-right-widget .crw-margin-right-44, #cover-right-widget .crw-mr-44 {
  margin-right: 44px !important;
}
#cover-right-widget .crw-margin-bottom-44, #cover-right-widget .crw-mb-44 {
  margin-bottom: 44px !important;
}
#cover-right-widget .crw-margin-left-44, #cover-right-widget .crw-ml-44 {
  margin-left: 44px !important;
}
#cover-right-widget .crw-margin-horizontal-44, #cover-right-widget .crw-mh-44 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}
#cover-right-widget .crw-margin-vertical-44, #cover-right-widget .crw-mv-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}
#cover-right-widget .crw-margin-48, #cover-right-widget .crw-m-48 {
  margin: 48px !important;
}
#cover-right-widget .crw-margin-top-48, #cover-right-widget .crw-mt-48 {
  margin-top: 48px !important;
}
#cover-right-widget .crw-margin-right-48, #cover-right-widget .crw-mr-48 {
  margin-right: 48px !important;
}
#cover-right-widget .crw-margin-bottom-48, #cover-right-widget .crw-mb-48 {
  margin-bottom: 48px !important;
}
#cover-right-widget .crw-margin-left-48, #cover-right-widget .crw-ml-48 {
  margin-left: 48px !important;
}
#cover-right-widget .crw-margin-horizontal-48, #cover-right-widget .crw-mh-48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}
#cover-right-widget .crw-margin-vertical-48, #cover-right-widget .crw-mv-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
#cover-right-widget .crw-margin-52, #cover-right-widget .crw-m-52 {
  margin: 52px !important;
}
#cover-right-widget .crw-margin-top-52, #cover-right-widget .crw-mt-52 {
  margin-top: 52px !important;
}
#cover-right-widget .crw-margin-right-52, #cover-right-widget .crw-mr-52 {
  margin-right: 52px !important;
}
#cover-right-widget .crw-margin-bottom-52, #cover-right-widget .crw-mb-52 {
  margin-bottom: 52px !important;
}
#cover-right-widget .crw-margin-left-52, #cover-right-widget .crw-ml-52 {
  margin-left: 52px !important;
}
#cover-right-widget .crw-margin-horizontal-52, #cover-right-widget .crw-mh-52 {
  margin-left: 52px !important;
  margin-right: 52px !important;
}
#cover-right-widget .crw-margin-vertical-52, #cover-right-widget .crw-mv-52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}
#cover-right-widget .crw-margin-56, #cover-right-widget .crw-m-56 {
  margin: 56px !important;
}
#cover-right-widget .crw-margin-top-56, #cover-right-widget .crw-mt-56 {
  margin-top: 56px !important;
}
#cover-right-widget .crw-margin-right-56, #cover-right-widget .crw-mr-56 {
  margin-right: 56px !important;
}
#cover-right-widget .crw-margin-bottom-56, #cover-right-widget .crw-mb-56 {
  margin-bottom: 56px !important;
}
#cover-right-widget .crw-margin-left-56, #cover-right-widget .crw-ml-56 {
  margin-left: 56px !important;
}
#cover-right-widget .crw-margin-horizontal-56, #cover-right-widget .crw-mh-56 {
  margin-left: 56px !important;
  margin-right: 56px !important;
}
#cover-right-widget .crw-margin-vertical-56, #cover-right-widget .crw-mv-56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}
#cover-right-widget .crw-margin-64, #cover-right-widget .crw-m-64 {
  margin: 64px !important;
}
#cover-right-widget .crw-margin-top-64, #cover-right-widget .crw-mt-64 {
  margin-top: 64px !important;
}
#cover-right-widget .crw-margin-right-64, #cover-right-widget .crw-mr-64 {
  margin-right: 64px !important;
}
#cover-right-widget .crw-margin-bottom-64, #cover-right-widget .crw-mb-64 {
  margin-bottom: 64px !important;
}
#cover-right-widget .crw-margin-left-64, #cover-right-widget .crw-ml-64 {
  margin-left: 64px !important;
}
#cover-right-widget .crw-margin-horizontal-64, #cover-right-widget .crw-mh-64 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}
#cover-right-widget .crw-margin-vertical-64, #cover-right-widget .crw-mv-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}
#cover-right-widget .crw-margin-68, #cover-right-widget .crw-m-68 {
  margin: 68px !important;
}
#cover-right-widget .crw-margin-top-68, #cover-right-widget .crw-mt-68 {
  margin-top: 68px !important;
}
#cover-right-widget .crw-margin-right-68, #cover-right-widget .crw-mr-68 {
  margin-right: 68px !important;
}
#cover-right-widget .crw-margin-bottom-68, #cover-right-widget .crw-mb-68 {
  margin-bottom: 68px !important;
}
#cover-right-widget .crw-margin-left-68, #cover-right-widget .crw-ml-68 {
  margin-left: 68px !important;
}
#cover-right-widget .crw-margin-horizontal-68, #cover-right-widget .crw-mh-68 {
  margin-left: 68px !important;
  margin-right: 68px !important;
}
#cover-right-widget .crw-margin-vertical-68, #cover-right-widget .crw-mv-68 {
  margin-top: 68px !important;
  margin-bottom: 68px !important;
}
#cover-right-widget .crw-margin-72, #cover-right-widget .crw-m-72 {
  margin: 72px !important;
}
#cover-right-widget .crw-margin-top-72, #cover-right-widget .crw-mt-72 {
  margin-top: 72px !important;
}
#cover-right-widget .crw-margin-right-72, #cover-right-widget .crw-mr-72 {
  margin-right: 72px !important;
}
#cover-right-widget .crw-margin-bottom-72, #cover-right-widget .crw-mb-72 {
  margin-bottom: 72px !important;
}
#cover-right-widget .crw-margin-left-72, #cover-right-widget .crw-ml-72 {
  margin-left: 72px !important;
}
#cover-right-widget .crw-margin-horizontal-72, #cover-right-widget .crw-mh-72 {
  margin-left: 72px !important;
  margin-right: 72px !important;
}
#cover-right-widget .crw-margin-vertical-72, #cover-right-widget .crw-mv-72 {
  margin-top: 72px !important;
  margin-bottom: 72px !important;
}
#cover-right-widget .crw-margin-76, #cover-right-widget .crw-m-76 {
  margin: 76px !important;
}
#cover-right-widget .crw-margin-top-76, #cover-right-widget .crw-mt-76 {
  margin-top: 76px !important;
}
#cover-right-widget .crw-margin-right-76, #cover-right-widget .crw-mr-76 {
  margin-right: 76px !important;
}
#cover-right-widget .crw-margin-bottom-76, #cover-right-widget .crw-mb-76 {
  margin-bottom: 76px !important;
}
#cover-right-widget .crw-margin-left-76, #cover-right-widget .crw-ml-76 {
  margin-left: 76px !important;
}
#cover-right-widget .crw-margin-horizontal-76, #cover-right-widget .crw-mh-76 {
  margin-left: 76px !important;
  margin-right: 76px !important;
}
#cover-right-widget .crw-margin-vertical-76, #cover-right-widget .crw-mv-76 {
  margin-top: 76px !important;
  margin-bottom: 76px !important;
}
#cover-right-widget .crw-margin-84, #cover-right-widget .crw-m-84 {
  margin: 84px !important;
}
#cover-right-widget .crw-margin-top-84, #cover-right-widget .crw-mt-84 {
  margin-top: 84px !important;
}
#cover-right-widget .crw-margin-right-84, #cover-right-widget .crw-mr-84 {
  margin-right: 84px !important;
}
#cover-right-widget .crw-margin-bottom-84, #cover-right-widget .crw-mb-84 {
  margin-bottom: 84px !important;
}
#cover-right-widget .crw-margin-left-84, #cover-right-widget .crw-ml-84 {
  margin-left: 84px !important;
}
#cover-right-widget .crw-margin-horizontal-84, #cover-right-widget .crw-mh-84 {
  margin-left: 84px !important;
  margin-right: 84px !important;
}
#cover-right-widget .crw-margin-vertical-84, #cover-right-widget .crw-mv-84 {
  margin-top: 84px !important;
  margin-bottom: 84px !important;
}
#cover-right-widget .crw-margin-88, #cover-right-widget .crw-m-88 {
  margin: 88px !important;
}
#cover-right-widget .crw-margin-top-88, #cover-right-widget .crw-mt-88 {
  margin-top: 88px !important;
}
#cover-right-widget .crw-margin-right-88, #cover-right-widget .crw-mr-88 {
  margin-right: 88px !important;
}
#cover-right-widget .crw-margin-bottom-88, #cover-right-widget .crw-mb-88 {
  margin-bottom: 88px !important;
}
#cover-right-widget .crw-margin-left-88, #cover-right-widget .crw-ml-88 {
  margin-left: 88px !important;
}
#cover-right-widget .crw-margin-horizontal-88, #cover-right-widget .crw-mh-88 {
  margin-left: 88px !important;
  margin-right: 88px !important;
}
#cover-right-widget .crw-margin-vertical-88, #cover-right-widget .crw-mv-88 {
  margin-top: 88px !important;
  margin-bottom: 88px !important;
}
#cover-right-widget .crw-margin-92, #cover-right-widget .crw-m-92 {
  margin: 92px !important;
}
#cover-right-widget .crw-margin-top-92, #cover-right-widget .crw-mt-92 {
  margin-top: 92px !important;
}
#cover-right-widget .crw-margin-right-92, #cover-right-widget .crw-mr-92 {
  margin-right: 92px !important;
}
#cover-right-widget .crw-margin-bottom-92, #cover-right-widget .crw-mb-92 {
  margin-bottom: 92px !important;
}
#cover-right-widget .crw-margin-left-92, #cover-right-widget .crw-ml-92 {
  margin-left: 92px !important;
}
#cover-right-widget .crw-margin-horizontal-92, #cover-right-widget .crw-mh-92 {
  margin-left: 92px !important;
  margin-right: 92px !important;
}
#cover-right-widget .crw-margin-vertical-92, #cover-right-widget .crw-mv-92 {
  margin-top: 92px !important;
  margin-bottom: 92px !important;
}
#cover-right-widget .crw-margin-96, #cover-right-widget .crw-m-96 {
  margin: 96px !important;
}
#cover-right-widget .crw-margin-top-96, #cover-right-widget .crw-mt-96 {
  margin-top: 96px !important;
}
#cover-right-widget .crw-margin-right-96, #cover-right-widget .crw-mr-96 {
  margin-right: 96px !important;
}
#cover-right-widget .crw-margin-bottom-96, #cover-right-widget .crw-mb-96 {
  margin-bottom: 96px !important;
}
#cover-right-widget .crw-margin-left-96, #cover-right-widget .crw-ml-96 {
  margin-left: 96px !important;
}
#cover-right-widget .crw-margin-horizontal-96, #cover-right-widget .crw-mh-96 {
  margin-left: 96px !important;
  margin-right: 96px !important;
}
#cover-right-widget .crw-margin-vertical-96, #cover-right-widget .crw-mv-96 {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}
#cover-right-widget .crw-height-0, #cover-right-widget .crw-h-0 {
  height: 0px !important;
  min-height: 0px !important;
  max-height: 0px !important;
}
#cover-right-widget .crw-width-0, #cover-right-widget .crw-w-0 {
  width: 0px !important;
  min-width: 0px !important;
  max-width: 0px !important;
}
#cover-right-widget .crw-height-5, #cover-right-widget .crw-h-5 {
  height: 5px !important;
  min-height: 5px !important;
  max-height: 5px !important;
}
#cover-right-widget .crw-width-5, #cover-right-widget .crw-w-5 {
  width: 5px !important;
  min-width: 5px !important;
  max-width: 5px !important;
}
#cover-right-widget .crw-height-10, #cover-right-widget .crw-h-10 {
  height: 10px !important;
  min-height: 10px !important;
  max-height: 10px !important;
}
#cover-right-widget .crw-width-10, #cover-right-widget .crw-w-10 {
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important;
}
#cover-right-widget .crw-height-15, #cover-right-widget .crw-h-15 {
  height: 15px !important;
  min-height: 15px !important;
  max-height: 15px !important;
}
#cover-right-widget .crw-width-15, #cover-right-widget .crw-w-15 {
  width: 15px !important;
  min-width: 15px !important;
  max-width: 15px !important;
}
#cover-right-widget .crw-height-20, #cover-right-widget .crw-h-20 {
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important;
}
#cover-right-widget .crw-width-20, #cover-right-widget .crw-w-20 {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important;
}
#cover-right-widget .crw-height-25, #cover-right-widget .crw-h-25 {
  height: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important;
}
#cover-right-widget .crw-width-25, #cover-right-widget .crw-w-25 {
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important;
}
#cover-right-widget .crw-height-30, #cover-right-widget .crw-h-30 {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
}
#cover-right-widget .crw-width-30, #cover-right-widget .crw-w-30 {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important;
}
#cover-right-widget .crw-height-35, #cover-right-widget .crw-h-35 {
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important;
}
#cover-right-widget .crw-width-35, #cover-right-widget .crw-w-35 {
  width: 35px !important;
  min-width: 35px !important;
  max-width: 35px !important;
}
#cover-right-widget .crw-height-40, #cover-right-widget .crw-h-40 {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}
#cover-right-widget .crw-width-40, #cover-right-widget .crw-w-40 {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
}
#cover-right-widget .crw-height-45, #cover-right-widget .crw-h-45 {
  height: 45px !important;
  min-height: 45px !important;
  max-height: 45px !important;
}
#cover-right-widget .crw-width-45, #cover-right-widget .crw-w-45 {
  width: 45px !important;
  min-width: 45px !important;
  max-width: 45px !important;
}
#cover-right-widget .crw-height-50, #cover-right-widget .crw-h-50 {
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
}
#cover-right-widget .crw-width-50, #cover-right-widget .crw-w-50 {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important;
}
#cover-right-widget .crw-height-55, #cover-right-widget .crw-h-55 {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important;
}
#cover-right-widget .crw-width-55, #cover-right-widget .crw-w-55 {
  width: 55px !important;
  min-width: 55px !important;
  max-width: 55px !important;
}
#cover-right-widget .crw-height-60, #cover-right-widget .crw-h-60 {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
}
#cover-right-widget .crw-width-60, #cover-right-widget .crw-w-60 {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
}
#cover-right-widget .crw-height-65, #cover-right-widget .crw-h-65 {
  height: 65px !important;
  min-height: 65px !important;
  max-height: 65px !important;
}
#cover-right-widget .crw-width-65, #cover-right-widget .crw-w-65 {
  width: 65px !important;
  min-width: 65px !important;
  max-width: 65px !important;
}
#cover-right-widget .crw-height-70, #cover-right-widget .crw-h-70 {
  height: 70px !important;
  min-height: 70px !important;
  max-height: 70px !important;
}
#cover-right-widget .crw-width-70, #cover-right-widget .crw-w-70 {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
}
#cover-right-widget .crw-height-75, #cover-right-widget .crw-h-75 {
  height: 75px !important;
  min-height: 75px !important;
  max-height: 75px !important;
}
#cover-right-widget .crw-width-75, #cover-right-widget .crw-w-75 {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important;
}
#cover-right-widget .crw-height-80, #cover-right-widget .crw-h-80 {
  height: 80px !important;
  min-height: 80px !important;
  max-height: 80px !important;
}
#cover-right-widget .crw-width-80, #cover-right-widget .crw-w-80 {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}
#cover-right-widget .crw-height-85, #cover-right-widget .crw-h-85 {
  height: 85px !important;
  min-height: 85px !important;
  max-height: 85px !important;
}
#cover-right-widget .crw-width-85, #cover-right-widget .crw-w-85 {
  width: 85px !important;
  min-width: 85px !important;
  max-width: 85px !important;
}
#cover-right-widget .crw-height-90, #cover-right-widget .crw-h-90 {
  height: 90px !important;
  min-height: 90px !important;
  max-height: 90px !important;
}
#cover-right-widget .crw-width-90, #cover-right-widget .crw-w-90 {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important;
}
#cover-right-widget .crw-height-95, #cover-right-widget .crw-h-95 {
  height: 95px !important;
  min-height: 95px !important;
  max-height: 95px !important;
}
#cover-right-widget .crw-width-95, #cover-right-widget .crw-w-95 {
  width: 95px !important;
  min-width: 95px !important;
  max-width: 95px !important;
}
#cover-right-widget .crw-height-100, #cover-right-widget .crw-h-100 {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important;
}
#cover-right-widget .crw-width-100, #cover-right-widget .crw-w-100 {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}
#cover-right-widget .crw-height-105, #cover-right-widget .crw-h-105 {
  height: 105px !important;
  min-height: 105px !important;
  max-height: 105px !important;
}
#cover-right-widget .crw-width-105, #cover-right-widget .crw-w-105 {
  width: 105px !important;
  min-width: 105px !important;
  max-width: 105px !important;
}
#cover-right-widget .crw-height-110, #cover-right-widget .crw-h-110 {
  height: 110px !important;
  min-height: 110px !important;
  max-height: 110px !important;
}
#cover-right-widget .crw-width-110, #cover-right-widget .crw-w-110 {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important;
}
#cover-right-widget .crw-height-115, #cover-right-widget .crw-h-115 {
  height: 115px !important;
  min-height: 115px !important;
  max-height: 115px !important;
}
#cover-right-widget .crw-width-115, #cover-right-widget .crw-w-115 {
  width: 115px !important;
  min-width: 115px !important;
  max-width: 115px !important;
}
#cover-right-widget .crw-height-120, #cover-right-widget .crw-h-120 {
  height: 120px !important;
  min-height: 120px !important;
  max-height: 120px !important;
}
#cover-right-widget .crw-width-120, #cover-right-widget .crw-w-120 {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}
#cover-right-widget .crw-height-125, #cover-right-widget .crw-h-125 {
  height: 125px !important;
  min-height: 125px !important;
  max-height: 125px !important;
}
#cover-right-widget .crw-width-125, #cover-right-widget .crw-w-125 {
  width: 125px !important;
  min-width: 125px !important;
  max-width: 125px !important;
}
#cover-right-widget .crw-height-130, #cover-right-widget .crw-h-130 {
  height: 130px !important;
  min-height: 130px !important;
  max-height: 130px !important;
}
#cover-right-widget .crw-width-130, #cover-right-widget .crw-w-130 {
  width: 130px !important;
  min-width: 130px !important;
  max-width: 130px !important;
}
#cover-right-widget .crw-height-135, #cover-right-widget .crw-h-135 {
  height: 135px !important;
  min-height: 135px !important;
  max-height: 135px !important;
}
#cover-right-widget .crw-width-135, #cover-right-widget .crw-w-135 {
  width: 135px !important;
  min-width: 135px !important;
  max-width: 135px !important;
}
#cover-right-widget .crw-height-140, #cover-right-widget .crw-h-140 {
  height: 140px !important;
  min-height: 140px !important;
  max-height: 140px !important;
}
#cover-right-widget .crw-width-140, #cover-right-widget .crw-w-140 {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important;
}
#cover-right-widget .crw-height-145, #cover-right-widget .crw-h-145 {
  height: 145px !important;
  min-height: 145px !important;
  max-height: 145px !important;
}
#cover-right-widget .crw-width-145, #cover-right-widget .crw-w-145 {
  width: 145px !important;
  min-width: 145px !important;
  max-width: 145px !important;
}
#cover-right-widget .crw-height-150, #cover-right-widget .crw-h-150 {
  height: 150px !important;
  min-height: 150px !important;
  max-height: 150px !important;
}
#cover-right-widget .crw-width-150, #cover-right-widget .crw-w-150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important;
}
#cover-right-widget .crw-height-155, #cover-right-widget .crw-h-155 {
  height: 155px !important;
  min-height: 155px !important;
  max-height: 155px !important;
}
#cover-right-widget .crw-width-155, #cover-right-widget .crw-w-155 {
  width: 155px !important;
  min-width: 155px !important;
  max-width: 155px !important;
}
#cover-right-widget .crw-height-160, #cover-right-widget .crw-h-160 {
  height: 160px !important;
  min-height: 160px !important;
  max-height: 160px !important;
}
#cover-right-widget .crw-width-160, #cover-right-widget .crw-w-160 {
  width: 160px !important;
  min-width: 160px !important;
  max-width: 160px !important;
}
#cover-right-widget .crw-height-165, #cover-right-widget .crw-h-165 {
  height: 165px !important;
  min-height: 165px !important;
  max-height: 165px !important;
}
#cover-right-widget .crw-width-165, #cover-right-widget .crw-w-165 {
  width: 165px !important;
  min-width: 165px !important;
  max-width: 165px !important;
}
#cover-right-widget .crw-height-170, #cover-right-widget .crw-h-170 {
  height: 170px !important;
  min-height: 170px !important;
  max-height: 170px !important;
}
#cover-right-widget .crw-width-170, #cover-right-widget .crw-w-170 {
  width: 170px !important;
  min-width: 170px !important;
  max-width: 170px !important;
}
#cover-right-widget .crw-height-175, #cover-right-widget .crw-h-175 {
  height: 175px !important;
  min-height: 175px !important;
  max-height: 175px !important;
}
#cover-right-widget .crw-width-175, #cover-right-widget .crw-w-175 {
  width: 175px !important;
  min-width: 175px !important;
  max-width: 175px !important;
}
#cover-right-widget .crw-height-180, #cover-right-widget .crw-h-180 {
  height: 180px !important;
  min-height: 180px !important;
  max-height: 180px !important;
}
#cover-right-widget .crw-width-180, #cover-right-widget .crw-w-180 {
  width: 180px !important;
  min-width: 180px !important;
  max-width: 180px !important;
}
#cover-right-widget .crw-height-185, #cover-right-widget .crw-h-185 {
  height: 185px !important;
  min-height: 185px !important;
  max-height: 185px !important;
}
#cover-right-widget .crw-width-185, #cover-right-widget .crw-w-185 {
  width: 185px !important;
  min-width: 185px !important;
  max-width: 185px !important;
}
#cover-right-widget .crw-height-190, #cover-right-widget .crw-h-190 {
  height: 190px !important;
  min-height: 190px !important;
  max-height: 190px !important;
}
#cover-right-widget .crw-width-190, #cover-right-widget .crw-w-190 {
  width: 190px !important;
  min-width: 190px !important;
  max-width: 190px !important;
}
#cover-right-widget .crw-height-195, #cover-right-widget .crw-h-195 {
  height: 195px !important;
  min-height: 195px !important;
  max-height: 195px !important;
}
#cover-right-widget .crw-width-195, #cover-right-widget .crw-w-195 {
  width: 195px !important;
  min-width: 195px !important;
  max-width: 195px !important;
}
#cover-right-widget .crw-height-200, #cover-right-widget .crw-h-200 {
  height: 200px !important;
  min-height: 200px !important;
  max-height: 200px !important;
}
#cover-right-widget .crw-width-200, #cover-right-widget .crw-w-200 {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important;
}
#cover-right-widget .crw-height-205, #cover-right-widget .crw-h-205 {
  height: 205px !important;
  min-height: 205px !important;
  max-height: 205px !important;
}
#cover-right-widget .crw-width-205, #cover-right-widget .crw-w-205 {
  width: 205px !important;
  min-width: 205px !important;
  max-width: 205px !important;
}
#cover-right-widget .crw-height-210, #cover-right-widget .crw-h-210 {
  height: 210px !important;
  min-height: 210px !important;
  max-height: 210px !important;
}
#cover-right-widget .crw-width-210, #cover-right-widget .crw-w-210 {
  width: 210px !important;
  min-width: 210px !important;
  max-width: 210px !important;
}
#cover-right-widget .crw-height-215, #cover-right-widget .crw-h-215 {
  height: 215px !important;
  min-height: 215px !important;
  max-height: 215px !important;
}
#cover-right-widget .crw-width-215, #cover-right-widget .crw-w-215 {
  width: 215px !important;
  min-width: 215px !important;
  max-width: 215px !important;
}
#cover-right-widget .crw-height-220, #cover-right-widget .crw-h-220 {
  height: 220px !important;
  min-height: 220px !important;
  max-height: 220px !important;
}
#cover-right-widget .crw-width-220, #cover-right-widget .crw-w-220 {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important;
}
#cover-right-widget .crw-height-225, #cover-right-widget .crw-h-225 {
  height: 225px !important;
  min-height: 225px !important;
  max-height: 225px !important;
}
#cover-right-widget .crw-width-225, #cover-right-widget .crw-w-225 {
  width: 225px !important;
  min-width: 225px !important;
  max-width: 225px !important;
}
#cover-right-widget .crw-height-230, #cover-right-widget .crw-h-230 {
  height: 230px !important;
  min-height: 230px !important;
  max-height: 230px !important;
}
#cover-right-widget .crw-width-230, #cover-right-widget .crw-w-230 {
  width: 230px !important;
  min-width: 230px !important;
  max-width: 230px !important;
}
#cover-right-widget .crw-height-235, #cover-right-widget .crw-h-235 {
  height: 235px !important;
  min-height: 235px !important;
  max-height: 235px !important;
}
#cover-right-widget .crw-width-235, #cover-right-widget .crw-w-235 {
  width: 235px !important;
  min-width: 235px !important;
  max-width: 235px !important;
}
#cover-right-widget .crw-height-240, #cover-right-widget .crw-h-240 {
  height: 240px !important;
  min-height: 240px !important;
  max-height: 240px !important;
}
#cover-right-widget .crw-width-240, #cover-right-widget .crw-w-240 {
  width: 240px !important;
  min-width: 240px !important;
  max-width: 240px !important;
}
#cover-right-widget .crw-height-245, #cover-right-widget .crw-h-245 {
  height: 245px !important;
  min-height: 245px !important;
  max-height: 245px !important;
}
#cover-right-widget .crw-width-245, #cover-right-widget .crw-w-245 {
  width: 245px !important;
  min-width: 245px !important;
  max-width: 245px !important;
}
#cover-right-widget .crw-height-250, #cover-right-widget .crw-h-250 {
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important;
}
#cover-right-widget .crw-width-250, #cover-right-widget .crw-w-250 {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
}
#cover-right-widget .crw-height-255, #cover-right-widget .crw-h-255 {
  height: 255px !important;
  min-height: 255px !important;
  max-height: 255px !important;
}
#cover-right-widget .crw-width-255, #cover-right-widget .crw-w-255 {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important;
}
#cover-right-widget .crw-height-260, #cover-right-widget .crw-h-260 {
  height: 260px !important;
  min-height: 260px !important;
  max-height: 260px !important;
}
#cover-right-widget .crw-width-260, #cover-right-widget .crw-w-260 {
  width: 260px !important;
  min-width: 260px !important;
  max-width: 260px !important;
}
#cover-right-widget .crw-height-265, #cover-right-widget .crw-h-265 {
  height: 265px !important;
  min-height: 265px !important;
  max-height: 265px !important;
}
#cover-right-widget .crw-width-265, #cover-right-widget .crw-w-265 {
  width: 265px !important;
  min-width: 265px !important;
  max-width: 265px !important;
}
#cover-right-widget .crw-height-270, #cover-right-widget .crw-h-270 {
  height: 270px !important;
  min-height: 270px !important;
  max-height: 270px !important;
}
#cover-right-widget .crw-width-270, #cover-right-widget .crw-w-270 {
  width: 270px !important;
  min-width: 270px !important;
  max-width: 270px !important;
}
#cover-right-widget .crw-height-275, #cover-right-widget .crw-h-275 {
  height: 275px !important;
  min-height: 275px !important;
  max-height: 275px !important;
}
#cover-right-widget .crw-width-275, #cover-right-widget .crw-w-275 {
  width: 275px !important;
  min-width: 275px !important;
  max-width: 275px !important;
}
#cover-right-widget .crw-height-280, #cover-right-widget .crw-h-280 {
  height: 280px !important;
  min-height: 280px !important;
  max-height: 280px !important;
}
#cover-right-widget .crw-width-280, #cover-right-widget .crw-w-280 {
  width: 280px !important;
  min-width: 280px !important;
  max-width: 280px !important;
}
#cover-right-widget .crw-height-285, #cover-right-widget .crw-h-285 {
  height: 285px !important;
  min-height: 285px !important;
  max-height: 285px !important;
}
#cover-right-widget .crw-width-285, #cover-right-widget .crw-w-285 {
  width: 285px !important;
  min-width: 285px !important;
  max-width: 285px !important;
}
#cover-right-widget .crw-height-290, #cover-right-widget .crw-h-290 {
  height: 290px !important;
  min-height: 290px !important;
  max-height: 290px !important;
}
#cover-right-widget .crw-width-290, #cover-right-widget .crw-w-290 {
  width: 290px !important;
  min-width: 290px !important;
  max-width: 290px !important;
}
#cover-right-widget .crw-height-295, #cover-right-widget .crw-h-295 {
  height: 295px !important;
  min-height: 295px !important;
  max-height: 295px !important;
}
#cover-right-widget .crw-width-295, #cover-right-widget .crw-w-295 {
  width: 295px !important;
  min-width: 295px !important;
  max-width: 295px !important;
}
#cover-right-widget .crw-height-300, #cover-right-widget .crw-h-300 {
  height: 300px !important;
  min-height: 300px !important;
  max-height: 300px !important;
}
#cover-right-widget .crw-width-300, #cover-right-widget .crw-w-300 {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important;
}
#cover-right-widget .crw-height-305, #cover-right-widget .crw-h-305 {
  height: 305px !important;
  min-height: 305px !important;
  max-height: 305px !important;
}
#cover-right-widget .crw-width-305, #cover-right-widget .crw-w-305 {
  width: 305px !important;
  min-width: 305px !important;
  max-width: 305px !important;
}
#cover-right-widget .crw-height-310, #cover-right-widget .crw-h-310 {
  height: 310px !important;
  min-height: 310px !important;
  max-height: 310px !important;
}
#cover-right-widget .crw-width-310, #cover-right-widget .crw-w-310 {
  width: 310px !important;
  min-width: 310px !important;
  max-width: 310px !important;
}
#cover-right-widget .crw-height-315, #cover-right-widget .crw-h-315 {
  height: 315px !important;
  min-height: 315px !important;
  max-height: 315px !important;
}
#cover-right-widget .crw-width-315, #cover-right-widget .crw-w-315 {
  width: 315px !important;
  min-width: 315px !important;
  max-width: 315px !important;
}
#cover-right-widget .crw-height-320, #cover-right-widget .crw-h-320 {
  height: 320px !important;
  min-height: 320px !important;
  max-height: 320px !important;
}
#cover-right-widget .crw-width-320, #cover-right-widget .crw-w-320 {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important;
}
#cover-right-widget .crw-height-325, #cover-right-widget .crw-h-325 {
  height: 325px !important;
  min-height: 325px !important;
  max-height: 325px !important;
}
#cover-right-widget .crw-width-325, #cover-right-widget .crw-w-325 {
  width: 325px !important;
  min-width: 325px !important;
  max-width: 325px !important;
}
#cover-right-widget .crw-height-330, #cover-right-widget .crw-h-330 {
  height: 330px !important;
  min-height: 330px !important;
  max-height: 330px !important;
}
#cover-right-widget .crw-width-330, #cover-right-widget .crw-w-330 {
  width: 330px !important;
  min-width: 330px !important;
  max-width: 330px !important;
}
#cover-right-widget .crw-height-335, #cover-right-widget .crw-h-335 {
  height: 335px !important;
  min-height: 335px !important;
  max-height: 335px !important;
}
#cover-right-widget .crw-width-335, #cover-right-widget .crw-w-335 {
  width: 335px !important;
  min-width: 335px !important;
  max-width: 335px !important;
}
#cover-right-widget .crw-height-340, #cover-right-widget .crw-h-340 {
  height: 340px !important;
  min-height: 340px !important;
  max-height: 340px !important;
}
#cover-right-widget .crw-width-340, #cover-right-widget .crw-w-340 {
  width: 340px !important;
  min-width: 340px !important;
  max-width: 340px !important;
}
#cover-right-widget .crw-height-345, #cover-right-widget .crw-h-345 {
  height: 345px !important;
  min-height: 345px !important;
  max-height: 345px !important;
}
#cover-right-widget .crw-width-345, #cover-right-widget .crw-w-345 {
  width: 345px !important;
  min-width: 345px !important;
  max-width: 345px !important;
}
#cover-right-widget .crw-height-350, #cover-right-widget .crw-h-350 {
  height: 350px !important;
  min-height: 350px !important;
  max-height: 350px !important;
}
#cover-right-widget .crw-width-350, #cover-right-widget .crw-w-350 {
  width: 350px !important;
  min-width: 350px !important;
  max-width: 350px !important;
}
#cover-right-widget .crw-height-355, #cover-right-widget .crw-h-355 {
  height: 355px !important;
  min-height: 355px !important;
  max-height: 355px !important;
}
#cover-right-widget .crw-width-355, #cover-right-widget .crw-w-355 {
  width: 355px !important;
  min-width: 355px !important;
  max-width: 355px !important;
}
#cover-right-widget .crw-height-360, #cover-right-widget .crw-h-360 {
  height: 360px !important;
  min-height: 360px !important;
  max-height: 360px !important;
}
#cover-right-widget .crw-width-360, #cover-right-widget .crw-w-360 {
  width: 360px !important;
  min-width: 360px !important;
  max-width: 360px !important;
}
#cover-right-widget .crw-height-365, #cover-right-widget .crw-h-365 {
  height: 365px !important;
  min-height: 365px !important;
  max-height: 365px !important;
}
#cover-right-widget .crw-width-365, #cover-right-widget .crw-w-365 {
  width: 365px !important;
  min-width: 365px !important;
  max-width: 365px !important;
}
#cover-right-widget .crw-height-370, #cover-right-widget .crw-h-370 {
  height: 370px !important;
  min-height: 370px !important;
  max-height: 370px !important;
}
#cover-right-widget .crw-width-370, #cover-right-widget .crw-w-370 {
  width: 370px !important;
  min-width: 370px !important;
  max-width: 370px !important;
}
#cover-right-widget .crw-height-375, #cover-right-widget .crw-h-375 {
  height: 375px !important;
  min-height: 375px !important;
  max-height: 375px !important;
}
#cover-right-widget .crw-width-375, #cover-right-widget .crw-w-375 {
  width: 375px !important;
  min-width: 375px !important;
  max-width: 375px !important;
}
#cover-right-widget .crw-height-380, #cover-right-widget .crw-h-380 {
  height: 380px !important;
  min-height: 380px !important;
  max-height: 380px !important;
}
#cover-right-widget .crw-width-380, #cover-right-widget .crw-w-380 {
  width: 380px !important;
  min-width: 380px !important;
  max-width: 380px !important;
}
#cover-right-widget .crw-height-385, #cover-right-widget .crw-h-385 {
  height: 385px !important;
  min-height: 385px !important;
  max-height: 385px !important;
}
#cover-right-widget .crw-width-385, #cover-right-widget .crw-w-385 {
  width: 385px !important;
  min-width: 385px !important;
  max-width: 385px !important;
}
#cover-right-widget .crw-height-390, #cover-right-widget .crw-h-390 {
  height: 390px !important;
  min-height: 390px !important;
  max-height: 390px !important;
}
#cover-right-widget .crw-width-390, #cover-right-widget .crw-w-390 {
  width: 390px !important;
  min-width: 390px !important;
  max-width: 390px !important;
}
#cover-right-widget .crw-height-395, #cover-right-widget .crw-h-395 {
  height: 395px !important;
  min-height: 395px !important;
  max-height: 395px !important;
}
#cover-right-widget .crw-width-395, #cover-right-widget .crw-w-395 {
  width: 395px !important;
  min-width: 395px !important;
  max-width: 395px !important;
}
#cover-right-widget .crw-height-400, #cover-right-widget .crw-h-400 {
  height: 400px !important;
  min-height: 400px !important;
  max-height: 400px !important;
}
#cover-right-widget .crw-width-400, #cover-right-widget .crw-w-400 {
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important;
}
#cover-right-widget .crw-height-405, #cover-right-widget .crw-h-405 {
  height: 405px !important;
  min-height: 405px !important;
  max-height: 405px !important;
}
#cover-right-widget .crw-width-405, #cover-right-widget .crw-w-405 {
  width: 405px !important;
  min-width: 405px !important;
  max-width: 405px !important;
}
#cover-right-widget .crw-height-410, #cover-right-widget .crw-h-410 {
  height: 410px !important;
  min-height: 410px !important;
  max-height: 410px !important;
}
#cover-right-widget .crw-width-410, #cover-right-widget .crw-w-410 {
  width: 410px !important;
  min-width: 410px !important;
  max-width: 410px !important;
}
#cover-right-widget .crw-height-415, #cover-right-widget .crw-h-415 {
  height: 415px !important;
  min-height: 415px !important;
  max-height: 415px !important;
}
#cover-right-widget .crw-width-415, #cover-right-widget .crw-w-415 {
  width: 415px !important;
  min-width: 415px !important;
  max-width: 415px !important;
}
#cover-right-widget .crw-height-420, #cover-right-widget .crw-h-420 {
  height: 420px !important;
  min-height: 420px !important;
  max-height: 420px !important;
}
#cover-right-widget .crw-width-420, #cover-right-widget .crw-w-420 {
  width: 420px !important;
  min-width: 420px !important;
  max-width: 420px !important;
}
#cover-right-widget .crw-height-425, #cover-right-widget .crw-h-425 {
  height: 425px !important;
  min-height: 425px !important;
  max-height: 425px !important;
}
#cover-right-widget .crw-width-425, #cover-right-widget .crw-w-425 {
  width: 425px !important;
  min-width: 425px !important;
  max-width: 425px !important;
}
#cover-right-widget .crw-height-430, #cover-right-widget .crw-h-430 {
  height: 430px !important;
  min-height: 430px !important;
  max-height: 430px !important;
}
#cover-right-widget .crw-width-430, #cover-right-widget .crw-w-430 {
  width: 430px !important;
  min-width: 430px !important;
  max-width: 430px !important;
}
#cover-right-widget .crw-height-435, #cover-right-widget .crw-h-435 {
  height: 435px !important;
  min-height: 435px !important;
  max-height: 435px !important;
}
#cover-right-widget .crw-width-435, #cover-right-widget .crw-w-435 {
  width: 435px !important;
  min-width: 435px !important;
  max-width: 435px !important;
}
#cover-right-widget .crw-height-440, #cover-right-widget .crw-h-440 {
  height: 440px !important;
  min-height: 440px !important;
  max-height: 440px !important;
}
#cover-right-widget .crw-width-440, #cover-right-widget .crw-w-440 {
  width: 440px !important;
  min-width: 440px !important;
  max-width: 440px !important;
}
#cover-right-widget .crw-height-445, #cover-right-widget .crw-h-445 {
  height: 445px !important;
  min-height: 445px !important;
  max-height: 445px !important;
}
#cover-right-widget .crw-width-445, #cover-right-widget .crw-w-445 {
  width: 445px !important;
  min-width: 445px !important;
  max-width: 445px !important;
}
#cover-right-widget .crw-height-450, #cover-right-widget .crw-h-450 {
  height: 450px !important;
  min-height: 450px !important;
  max-height: 450px !important;
}
#cover-right-widget .crw-width-450, #cover-right-widget .crw-w-450 {
  width: 450px !important;
  min-width: 450px !important;
  max-width: 450px !important;
}
#cover-right-widget .crw-height-455, #cover-right-widget .crw-h-455 {
  height: 455px !important;
  min-height: 455px !important;
  max-height: 455px !important;
}
#cover-right-widget .crw-width-455, #cover-right-widget .crw-w-455 {
  width: 455px !important;
  min-width: 455px !important;
  max-width: 455px !important;
}
#cover-right-widget .crw-height-460, #cover-right-widget .crw-h-460 {
  height: 460px !important;
  min-height: 460px !important;
  max-height: 460px !important;
}
#cover-right-widget .crw-width-460, #cover-right-widget .crw-w-460 {
  width: 460px !important;
  min-width: 460px !important;
  max-width: 460px !important;
}
#cover-right-widget .crw-height-465, #cover-right-widget .crw-h-465 {
  height: 465px !important;
  min-height: 465px !important;
  max-height: 465px !important;
}
#cover-right-widget .crw-width-465, #cover-right-widget .crw-w-465 {
  width: 465px !important;
  min-width: 465px !important;
  max-width: 465px !important;
}
#cover-right-widget .crw-height-470, #cover-right-widget .crw-h-470 {
  height: 470px !important;
  min-height: 470px !important;
  max-height: 470px !important;
}
#cover-right-widget .crw-width-470, #cover-right-widget .crw-w-470 {
  width: 470px !important;
  min-width: 470px !important;
  max-width: 470px !important;
}
#cover-right-widget .crw-height-475, #cover-right-widget .crw-h-475 {
  height: 475px !important;
  min-height: 475px !important;
  max-height: 475px !important;
}
#cover-right-widget .crw-width-475, #cover-right-widget .crw-w-475 {
  width: 475px !important;
  min-width: 475px !important;
  max-width: 475px !important;
}
#cover-right-widget .crw-height-480, #cover-right-widget .crw-h-480 {
  height: 480px !important;
  min-height: 480px !important;
  max-height: 480px !important;
}
#cover-right-widget .crw-width-480, #cover-right-widget .crw-w-480 {
  width: 480px !important;
  min-width: 480px !important;
  max-width: 480px !important;
}
#cover-right-widget .crw-height-485, #cover-right-widget .crw-h-485 {
  height: 485px !important;
  min-height: 485px !important;
  max-height: 485px !important;
}
#cover-right-widget .crw-width-485, #cover-right-widget .crw-w-485 {
  width: 485px !important;
  min-width: 485px !important;
  max-width: 485px !important;
}
#cover-right-widget .crw-height-490, #cover-right-widget .crw-h-490 {
  height: 490px !important;
  min-height: 490px !important;
  max-height: 490px !important;
}
#cover-right-widget .crw-width-490, #cover-right-widget .crw-w-490 {
  width: 490px !important;
  min-width: 490px !important;
  max-width: 490px !important;
}
#cover-right-widget .crw-height-495, #cover-right-widget .crw-h-495 {
  height: 495px !important;
  min-height: 495px !important;
  max-height: 495px !important;
}
#cover-right-widget .crw-width-495, #cover-right-widget .crw-w-495 {
  width: 495px !important;
  min-width: 495px !important;
  max-width: 495px !important;
}
#cover-right-widget .crw-height-500, #cover-right-widget .crw-h-500 {
  height: 500px !important;
  min-height: 500px !important;
  max-height: 500px !important;
}
#cover-right-widget .crw-width-500, #cover-right-widget .crw-w-500 {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important;
}
#cover-right-widget .crw-height-4, #cover-right-widget .crw-h-4 {
  height: 4px !important;
  min-height: 4px !important;
  max-height: 4px !important;
}
#cover-right-widget .crw-width-4, #cover-right-widget .crw-w-4 {
  width: 4px !important;
  min-width: 4px !important;
  max-width: 4px !important;
}
#cover-right-widget .crw-height-8, #cover-right-widget .crw-h-8 {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important;
}
#cover-right-widget .crw-width-8, #cover-right-widget .crw-w-8 {
  width: 8px !important;
  min-width: 8px !important;
  max-width: 8px !important;
}
#cover-right-widget .crw-height-12, #cover-right-widget .crw-h-12 {
  height: 12px !important;
  min-height: 12px !important;
  max-height: 12px !important;
}
#cover-right-widget .crw-width-12, #cover-right-widget .crw-w-12 {
  width: 12px !important;
  min-width: 12px !important;
  max-width: 12px !important;
}
#cover-right-widget .crw-height-16, #cover-right-widget .crw-h-16 {
  height: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important;
}
#cover-right-widget .crw-width-16, #cover-right-widget .crw-w-16 {
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important;
}
#cover-right-widget .crw-height-24, #cover-right-widget .crw-h-24 {
  height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important;
}
#cover-right-widget .crw-width-24, #cover-right-widget .crw-w-24 {
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important;
}
#cover-right-widget .crw-height-28, #cover-right-widget .crw-h-28 {
  height: 28px !important;
  min-height: 28px !important;
  max-height: 28px !important;
}
#cover-right-widget .crw-width-28, #cover-right-widget .crw-w-28 {
  width: 28px !important;
  min-width: 28px !important;
  max-width: 28px !important;
}
#cover-right-widget .crw-height-32, #cover-right-widget .crw-h-32 {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important;
}
#cover-right-widget .crw-width-32, #cover-right-widget .crw-w-32 {
  width: 32px !important;
  min-width: 32px !important;
  max-width: 32px !important;
}
#cover-right-widget .crw-height-36, #cover-right-widget .crw-h-36 {
  height: 36px !important;
  min-height: 36px !important;
  max-height: 36px !important;
}
#cover-right-widget .crw-width-36, #cover-right-widget .crw-w-36 {
  width: 36px !important;
  min-width: 36px !important;
  max-width: 36px !important;
}
#cover-right-widget .crw-height-44, #cover-right-widget .crw-h-44 {
  height: 44px !important;
  min-height: 44px !important;
  max-height: 44px !important;
}
#cover-right-widget .crw-width-44, #cover-right-widget .crw-w-44 {
  width: 44px !important;
  min-width: 44px !important;
  max-width: 44px !important;
}
#cover-right-widget .crw-height-48, #cover-right-widget .crw-h-48 {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important;
}
#cover-right-widget .crw-width-48, #cover-right-widget .crw-w-48 {
  width: 48px !important;
  min-width: 48px !important;
  max-width: 48px !important;
}
#cover-right-widget .crw-height-52, #cover-right-widget .crw-h-52 {
  height: 52px !important;
  min-height: 52px !important;
  max-height: 52px !important;
}
#cover-right-widget .crw-width-52, #cover-right-widget .crw-w-52 {
  width: 52px !important;
  min-width: 52px !important;
  max-width: 52px !important;
}
#cover-right-widget .crw-height-56, #cover-right-widget .crw-h-56 {
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important;
}
#cover-right-widget .crw-width-56, #cover-right-widget .crw-w-56 {
  width: 56px !important;
  min-width: 56px !important;
  max-width: 56px !important;
}
#cover-right-widget .crw-height-64, #cover-right-widget .crw-h-64 {
  height: 64px !important;
  min-height: 64px !important;
  max-height: 64px !important;
}
#cover-right-widget .crw-width-64, #cover-right-widget .crw-w-64 {
  width: 64px !important;
  min-width: 64px !important;
  max-width: 64px !important;
}
#cover-right-widget .crw-height-68, #cover-right-widget .crw-h-68 {
  height: 68px !important;
  min-height: 68px !important;
  max-height: 68px !important;
}
#cover-right-widget .crw-width-68, #cover-right-widget .crw-w-68 {
  width: 68px !important;
  min-width: 68px !important;
  max-width: 68px !important;
}
#cover-right-widget .crw-height-72, #cover-right-widget .crw-h-72 {
  height: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important;
}
#cover-right-widget .crw-width-72, #cover-right-widget .crw-w-72 {
  width: 72px !important;
  min-width: 72px !important;
  max-width: 72px !important;
}
#cover-right-widget .crw-height-76, #cover-right-widget .crw-h-76 {
  height: 76px !important;
  min-height: 76px !important;
  max-height: 76px !important;
}
#cover-right-widget .crw-width-76, #cover-right-widget .crw-w-76 {
  width: 76px !important;
  min-width: 76px !important;
  max-width: 76px !important;
}
#cover-right-widget .crw-height-84, #cover-right-widget .crw-h-84 {
  height: 84px !important;
  min-height: 84px !important;
  max-height: 84px !important;
}
#cover-right-widget .crw-width-84, #cover-right-widget .crw-w-84 {
  width: 84px !important;
  min-width: 84px !important;
  max-width: 84px !important;
}
#cover-right-widget .crw-height-88, #cover-right-widget .crw-h-88 {
  height: 88px !important;
  min-height: 88px !important;
  max-height: 88px !important;
}
#cover-right-widget .crw-width-88, #cover-right-widget .crw-w-88 {
  width: 88px !important;
  min-width: 88px !important;
  max-width: 88px !important;
}
#cover-right-widget .crw-height-92, #cover-right-widget .crw-h-92 {
  height: 92px !important;
  min-height: 92px !important;
  max-height: 92px !important;
}
#cover-right-widget .crw-width-92, #cover-right-widget .crw-w-92 {
  width: 92px !important;
  min-width: 92px !important;
  max-width: 92px !important;
}
#cover-right-widget .crw-height-96, #cover-right-widget .crw-h-96 {
  height: 96px !important;
  min-height: 96px !important;
  max-height: 96px !important;
}
#cover-right-widget .crw-width-96, #cover-right-widget .crw-w-96 {
  width: 96px !important;
  min-width: 96px !important;
  max-width: 96px !important;
}
#cover-right-widget .crw-height-104, #cover-right-widget .crw-h-104 {
  height: 104px !important;
  min-height: 104px !important;
  max-height: 104px !important;
}
#cover-right-widget .crw-width-104, #cover-right-widget .crw-w-104 {
  width: 104px !important;
  min-width: 104px !important;
  max-width: 104px !important;
}
#cover-right-widget .crw-height-108, #cover-right-widget .crw-h-108 {
  height: 108px !important;
  min-height: 108px !important;
  max-height: 108px !important;
}
#cover-right-widget .crw-width-108, #cover-right-widget .crw-w-108 {
  width: 108px !important;
  min-width: 108px !important;
  max-width: 108px !important;
}
#cover-right-widget .crw-height-112, #cover-right-widget .crw-h-112 {
  height: 112px !important;
  min-height: 112px !important;
  max-height: 112px !important;
}
#cover-right-widget .crw-width-112, #cover-right-widget .crw-w-112 {
  width: 112px !important;
  min-width: 112px !important;
  max-width: 112px !important;
}
#cover-right-widget .crw-height-116, #cover-right-widget .crw-h-116 {
  height: 116px !important;
  min-height: 116px !important;
  max-height: 116px !important;
}
#cover-right-widget .crw-width-116, #cover-right-widget .crw-w-116 {
  width: 116px !important;
  min-width: 116px !important;
  max-width: 116px !important;
}
#cover-right-widget .crw-height-124, #cover-right-widget .crw-h-124 {
  height: 124px !important;
  min-height: 124px !important;
  max-height: 124px !important;
}
#cover-right-widget .crw-width-124, #cover-right-widget .crw-w-124 {
  width: 124px !important;
  min-width: 124px !important;
  max-width: 124px !important;
}
#cover-right-widget .crw-height-128, #cover-right-widget .crw-h-128 {
  height: 128px !important;
  min-height: 128px !important;
  max-height: 128px !important;
}
#cover-right-widget .crw-width-128, #cover-right-widget .crw-w-128 {
  width: 128px !important;
  min-width: 128px !important;
  max-width: 128px !important;
}
#cover-right-widget .crw-height-132, #cover-right-widget .crw-h-132 {
  height: 132px !important;
  min-height: 132px !important;
  max-height: 132px !important;
}
#cover-right-widget .crw-width-132, #cover-right-widget .crw-w-132 {
  width: 132px !important;
  min-width: 132px !important;
  max-width: 132px !important;
}
#cover-right-widget .crw-height-136, #cover-right-widget .crw-h-136 {
  height: 136px !important;
  min-height: 136px !important;
  max-height: 136px !important;
}
#cover-right-widget .crw-width-136, #cover-right-widget .crw-w-136 {
  width: 136px !important;
  min-width: 136px !important;
  max-width: 136px !important;
}
#cover-right-widget .crw-height-144, #cover-right-widget .crw-h-144 {
  height: 144px !important;
  min-height: 144px !important;
  max-height: 144px !important;
}
#cover-right-widget .crw-width-144, #cover-right-widget .crw-w-144 {
  width: 144px !important;
  min-width: 144px !important;
  max-width: 144px !important;
}
#cover-right-widget .crw-height-148, #cover-right-widget .crw-h-148 {
  height: 148px !important;
  min-height: 148px !important;
  max-height: 148px !important;
}
#cover-right-widget .crw-width-148, #cover-right-widget .crw-w-148 {
  width: 148px !important;
  min-width: 148px !important;
  max-width: 148px !important;
}
#cover-right-widget .crw-height-152, #cover-right-widget .crw-h-152 {
  height: 152px !important;
  min-height: 152px !important;
  max-height: 152px !important;
}
#cover-right-widget .crw-width-152, #cover-right-widget .crw-w-152 {
  width: 152px !important;
  min-width: 152px !important;
  max-width: 152px !important;
}
#cover-right-widget .crw-height-156, #cover-right-widget .crw-h-156 {
  height: 156px !important;
  min-height: 156px !important;
  max-height: 156px !important;
}
#cover-right-widget .crw-width-156, #cover-right-widget .crw-w-156 {
  width: 156px !important;
  min-width: 156px !important;
  max-width: 156px !important;
}
#cover-right-widget .crw-height-164, #cover-right-widget .crw-h-164 {
  height: 164px !important;
  min-height: 164px !important;
  max-height: 164px !important;
}
#cover-right-widget .crw-width-164, #cover-right-widget .crw-w-164 {
  width: 164px !important;
  min-width: 164px !important;
  max-width: 164px !important;
}
#cover-right-widget .crw-height-168, #cover-right-widget .crw-h-168 {
  height: 168px !important;
  min-height: 168px !important;
  max-height: 168px !important;
}
#cover-right-widget .crw-width-168, #cover-right-widget .crw-w-168 {
  width: 168px !important;
  min-width: 168px !important;
  max-width: 168px !important;
}
#cover-right-widget .crw-height-172, #cover-right-widget .crw-h-172 {
  height: 172px !important;
  min-height: 172px !important;
  max-height: 172px !important;
}
#cover-right-widget .crw-width-172, #cover-right-widget .crw-w-172 {
  width: 172px !important;
  min-width: 172px !important;
  max-width: 172px !important;
}
#cover-right-widget .crw-height-176, #cover-right-widget .crw-h-176 {
  height: 176px !important;
  min-height: 176px !important;
  max-height: 176px !important;
}
#cover-right-widget .crw-width-176, #cover-right-widget .crw-w-176 {
  width: 176px !important;
  min-width: 176px !important;
  max-width: 176px !important;
}
#cover-right-widget .crw-height-184, #cover-right-widget .crw-h-184 {
  height: 184px !important;
  min-height: 184px !important;
  max-height: 184px !important;
}
#cover-right-widget .crw-width-184, #cover-right-widget .crw-w-184 {
  width: 184px !important;
  min-width: 184px !important;
  max-width: 184px !important;
}
#cover-right-widget .crw-height-188, #cover-right-widget .crw-h-188 {
  height: 188px !important;
  min-height: 188px !important;
  max-height: 188px !important;
}
#cover-right-widget .crw-width-188, #cover-right-widget .crw-w-188 {
  width: 188px !important;
  min-width: 188px !important;
  max-width: 188px !important;
}
#cover-right-widget .crw-height-192, #cover-right-widget .crw-h-192 {
  height: 192px !important;
  min-height: 192px !important;
  max-height: 192px !important;
}
#cover-right-widget .crw-width-192, #cover-right-widget .crw-w-192 {
  width: 192px !important;
  min-width: 192px !important;
  max-width: 192px !important;
}
#cover-right-widget .crw-height-196, #cover-right-widget .crw-h-196 {
  height: 196px !important;
  min-height: 196px !important;
  max-height: 196px !important;
}
#cover-right-widget .crw-width-196, #cover-right-widget .crw-w-196 {
  width: 196px !important;
  min-width: 196px !important;
  max-width: 196px !important;
}
#cover-right-widget .crw-height-204, #cover-right-widget .crw-h-204 {
  height: 204px !important;
  min-height: 204px !important;
  max-height: 204px !important;
}
#cover-right-widget .crw-width-204, #cover-right-widget .crw-w-204 {
  width: 204px !important;
  min-width: 204px !important;
  max-width: 204px !important;
}
#cover-right-widget .crw-height-208, #cover-right-widget .crw-h-208 {
  height: 208px !important;
  min-height: 208px !important;
  max-height: 208px !important;
}
#cover-right-widget .crw-width-208, #cover-right-widget .crw-w-208 {
  width: 208px !important;
  min-width: 208px !important;
  max-width: 208px !important;
}
#cover-right-widget .crw-height-212, #cover-right-widget .crw-h-212 {
  height: 212px !important;
  min-height: 212px !important;
  max-height: 212px !important;
}
#cover-right-widget .crw-width-212, #cover-right-widget .crw-w-212 {
  width: 212px !important;
  min-width: 212px !important;
  max-width: 212px !important;
}
#cover-right-widget .crw-height-216, #cover-right-widget .crw-h-216 {
  height: 216px !important;
  min-height: 216px !important;
  max-height: 216px !important;
}
#cover-right-widget .crw-width-216, #cover-right-widget .crw-w-216 {
  width: 216px !important;
  min-width: 216px !important;
  max-width: 216px !important;
}
#cover-right-widget .crw-height-224, #cover-right-widget .crw-h-224 {
  height: 224px !important;
  min-height: 224px !important;
  max-height: 224px !important;
}
#cover-right-widget .crw-width-224, #cover-right-widget .crw-w-224 {
  width: 224px !important;
  min-width: 224px !important;
  max-width: 224px !important;
}
#cover-right-widget .crw-height-228, #cover-right-widget .crw-h-228 {
  height: 228px !important;
  min-height: 228px !important;
  max-height: 228px !important;
}
#cover-right-widget .crw-width-228, #cover-right-widget .crw-w-228 {
  width: 228px !important;
  min-width: 228px !important;
  max-width: 228px !important;
}
#cover-right-widget .crw-height-232, #cover-right-widget .crw-h-232 {
  height: 232px !important;
  min-height: 232px !important;
  max-height: 232px !important;
}
#cover-right-widget .crw-width-232, #cover-right-widget .crw-w-232 {
  width: 232px !important;
  min-width: 232px !important;
  max-width: 232px !important;
}
#cover-right-widget .crw-height-236, #cover-right-widget .crw-h-236 {
  height: 236px !important;
  min-height: 236px !important;
  max-height: 236px !important;
}
#cover-right-widget .crw-width-236, #cover-right-widget .crw-w-236 {
  width: 236px !important;
  min-width: 236px !important;
  max-width: 236px !important;
}
#cover-right-widget .crw-height-244, #cover-right-widget .crw-h-244 {
  height: 244px !important;
  min-height: 244px !important;
  max-height: 244px !important;
}
#cover-right-widget .crw-width-244, #cover-right-widget .crw-w-244 {
  width: 244px !important;
  min-width: 244px !important;
  max-width: 244px !important;
}
#cover-right-widget .crw-height-248, #cover-right-widget .crw-h-248 {
  height: 248px !important;
  min-height: 248px !important;
  max-height: 248px !important;
}
#cover-right-widget .crw-width-248, #cover-right-widget .crw-w-248 {
  width: 248px !important;
  min-width: 248px !important;
  max-width: 248px !important;
}
#cover-right-widget .crw-height-252, #cover-right-widget .crw-h-252 {
  height: 252px !important;
  min-height: 252px !important;
  max-height: 252px !important;
}
#cover-right-widget .crw-width-252, #cover-right-widget .crw-w-252 {
  width: 252px !important;
  min-width: 252px !important;
  max-width: 252px !important;
}
#cover-right-widget .crw-height-256, #cover-right-widget .crw-h-256 {
  height: 256px !important;
  min-height: 256px !important;
  max-height: 256px !important;
}
#cover-right-widget .crw-width-256, #cover-right-widget .crw-w-256 {
  width: 256px !important;
  min-width: 256px !important;
  max-width: 256px !important;
}
#cover-right-widget .crw-height-264, #cover-right-widget .crw-h-264 {
  height: 264px !important;
  min-height: 264px !important;
  max-height: 264px !important;
}
#cover-right-widget .crw-width-264, #cover-right-widget .crw-w-264 {
  width: 264px !important;
  min-width: 264px !important;
  max-width: 264px !important;
}
#cover-right-widget .crw-height-268, #cover-right-widget .crw-h-268 {
  height: 268px !important;
  min-height: 268px !important;
  max-height: 268px !important;
}
#cover-right-widget .crw-width-268, #cover-right-widget .crw-w-268 {
  width: 268px !important;
  min-width: 268px !important;
  max-width: 268px !important;
}
#cover-right-widget .crw-height-272, #cover-right-widget .crw-h-272 {
  height: 272px !important;
  min-height: 272px !important;
  max-height: 272px !important;
}
#cover-right-widget .crw-width-272, #cover-right-widget .crw-w-272 {
  width: 272px !important;
  min-width: 272px !important;
  max-width: 272px !important;
}
#cover-right-widget .crw-height-276, #cover-right-widget .crw-h-276 {
  height: 276px !important;
  min-height: 276px !important;
  max-height: 276px !important;
}
#cover-right-widget .crw-width-276, #cover-right-widget .crw-w-276 {
  width: 276px !important;
  min-width: 276px !important;
  max-width: 276px !important;
}
#cover-right-widget .crw-height-284, #cover-right-widget .crw-h-284 {
  height: 284px !important;
  min-height: 284px !important;
  max-height: 284px !important;
}
#cover-right-widget .crw-width-284, #cover-right-widget .crw-w-284 {
  width: 284px !important;
  min-width: 284px !important;
  max-width: 284px !important;
}
#cover-right-widget .crw-height-288, #cover-right-widget .crw-h-288 {
  height: 288px !important;
  min-height: 288px !important;
  max-height: 288px !important;
}
#cover-right-widget .crw-width-288, #cover-right-widget .crw-w-288 {
  width: 288px !important;
  min-width: 288px !important;
  max-width: 288px !important;
}
#cover-right-widget .crw-height-292, #cover-right-widget .crw-h-292 {
  height: 292px !important;
  min-height: 292px !important;
  max-height: 292px !important;
}
#cover-right-widget .crw-width-292, #cover-right-widget .crw-w-292 {
  width: 292px !important;
  min-width: 292px !important;
  max-width: 292px !important;
}
#cover-right-widget .crw-height-296, #cover-right-widget .crw-h-296 {
  height: 296px !important;
  min-height: 296px !important;
  max-height: 296px !important;
}
#cover-right-widget .crw-width-296, #cover-right-widget .crw-w-296 {
  width: 296px !important;
  min-width: 296px !important;
  max-width: 296px !important;
}
#cover-right-widget .crw-height-304, #cover-right-widget .crw-h-304 {
  height: 304px !important;
  min-height: 304px !important;
  max-height: 304px !important;
}
#cover-right-widget .crw-width-304, #cover-right-widget .crw-w-304 {
  width: 304px !important;
  min-width: 304px !important;
  max-width: 304px !important;
}
#cover-right-widget .crw-height-308, #cover-right-widget .crw-h-308 {
  height: 308px !important;
  min-height: 308px !important;
  max-height: 308px !important;
}
#cover-right-widget .crw-width-308, #cover-right-widget .crw-w-308 {
  width: 308px !important;
  min-width: 308px !important;
  max-width: 308px !important;
}
#cover-right-widget .crw-height-312, #cover-right-widget .crw-h-312 {
  height: 312px !important;
  min-height: 312px !important;
  max-height: 312px !important;
}
#cover-right-widget .crw-width-312, #cover-right-widget .crw-w-312 {
  width: 312px !important;
  min-width: 312px !important;
  max-width: 312px !important;
}
#cover-right-widget .crw-height-316, #cover-right-widget .crw-h-316 {
  height: 316px !important;
  min-height: 316px !important;
  max-height: 316px !important;
}
#cover-right-widget .crw-width-316, #cover-right-widget .crw-w-316 {
  width: 316px !important;
  min-width: 316px !important;
  max-width: 316px !important;
}
#cover-right-widget .crw-height-324, #cover-right-widget .crw-h-324 {
  height: 324px !important;
  min-height: 324px !important;
  max-height: 324px !important;
}
#cover-right-widget .crw-width-324, #cover-right-widget .crw-w-324 {
  width: 324px !important;
  min-width: 324px !important;
  max-width: 324px !important;
}
#cover-right-widget .crw-height-328, #cover-right-widget .crw-h-328 {
  height: 328px !important;
  min-height: 328px !important;
  max-height: 328px !important;
}
#cover-right-widget .crw-width-328, #cover-right-widget .crw-w-328 {
  width: 328px !important;
  min-width: 328px !important;
  max-width: 328px !important;
}
#cover-right-widget .crw-height-332, #cover-right-widget .crw-h-332 {
  height: 332px !important;
  min-height: 332px !important;
  max-height: 332px !important;
}
#cover-right-widget .crw-width-332, #cover-right-widget .crw-w-332 {
  width: 332px !important;
  min-width: 332px !important;
  max-width: 332px !important;
}
#cover-right-widget .crw-height-336, #cover-right-widget .crw-h-336 {
  height: 336px !important;
  min-height: 336px !important;
  max-height: 336px !important;
}
#cover-right-widget .crw-width-336, #cover-right-widget .crw-w-336 {
  width: 336px !important;
  min-width: 336px !important;
  max-width: 336px !important;
}
#cover-right-widget .crw-height-344, #cover-right-widget .crw-h-344 {
  height: 344px !important;
  min-height: 344px !important;
  max-height: 344px !important;
}
#cover-right-widget .crw-width-344, #cover-right-widget .crw-w-344 {
  width: 344px !important;
  min-width: 344px !important;
  max-width: 344px !important;
}
#cover-right-widget .crw-height-348, #cover-right-widget .crw-h-348 {
  height: 348px !important;
  min-height: 348px !important;
  max-height: 348px !important;
}
#cover-right-widget .crw-width-348, #cover-right-widget .crw-w-348 {
  width: 348px !important;
  min-width: 348px !important;
  max-width: 348px !important;
}
#cover-right-widget .crw-height-352, #cover-right-widget .crw-h-352 {
  height: 352px !important;
  min-height: 352px !important;
  max-height: 352px !important;
}
#cover-right-widget .crw-width-352, #cover-right-widget .crw-w-352 {
  width: 352px !important;
  min-width: 352px !important;
  max-width: 352px !important;
}
#cover-right-widget .crw-height-356, #cover-right-widget .crw-h-356 {
  height: 356px !important;
  min-height: 356px !important;
  max-height: 356px !important;
}
#cover-right-widget .crw-width-356, #cover-right-widget .crw-w-356 {
  width: 356px !important;
  min-width: 356px !important;
  max-width: 356px !important;
}
#cover-right-widget .crw-height-364, #cover-right-widget .crw-h-364 {
  height: 364px !important;
  min-height: 364px !important;
  max-height: 364px !important;
}
#cover-right-widget .crw-width-364, #cover-right-widget .crw-w-364 {
  width: 364px !important;
  min-width: 364px !important;
  max-width: 364px !important;
}
#cover-right-widget .crw-height-368, #cover-right-widget .crw-h-368 {
  height: 368px !important;
  min-height: 368px !important;
  max-height: 368px !important;
}
#cover-right-widget .crw-width-368, #cover-right-widget .crw-w-368 {
  width: 368px !important;
  min-width: 368px !important;
  max-width: 368px !important;
}
#cover-right-widget .crw-height-372, #cover-right-widget .crw-h-372 {
  height: 372px !important;
  min-height: 372px !important;
  max-height: 372px !important;
}
#cover-right-widget .crw-width-372, #cover-right-widget .crw-w-372 {
  width: 372px !important;
  min-width: 372px !important;
  max-width: 372px !important;
}
#cover-right-widget .crw-height-376, #cover-right-widget .crw-h-376 {
  height: 376px !important;
  min-height: 376px !important;
  max-height: 376px !important;
}
#cover-right-widget .crw-width-376, #cover-right-widget .crw-w-376 {
  width: 376px !important;
  min-width: 376px !important;
  max-width: 376px !important;
}
#cover-right-widget .crw-height-384, #cover-right-widget .crw-h-384 {
  height: 384px !important;
  min-height: 384px !important;
  max-height: 384px !important;
}
#cover-right-widget .crw-width-384, #cover-right-widget .crw-w-384 {
  width: 384px !important;
  min-width: 384px !important;
  max-width: 384px !important;
}
#cover-right-widget .crw-height-388, #cover-right-widget .crw-h-388 {
  height: 388px !important;
  min-height: 388px !important;
  max-height: 388px !important;
}
#cover-right-widget .crw-width-388, #cover-right-widget .crw-w-388 {
  width: 388px !important;
  min-width: 388px !important;
  max-width: 388px !important;
}
#cover-right-widget .crw-height-392, #cover-right-widget .crw-h-392 {
  height: 392px !important;
  min-height: 392px !important;
  max-height: 392px !important;
}
#cover-right-widget .crw-width-392, #cover-right-widget .crw-w-392 {
  width: 392px !important;
  min-width: 392px !important;
  max-width: 392px !important;
}
#cover-right-widget .crw-height-396, #cover-right-widget .crw-h-396 {
  height: 396px !important;
  min-height: 396px !important;
  max-height: 396px !important;
}
#cover-right-widget .crw-width-396, #cover-right-widget .crw-w-396 {
  width: 396px !important;
  min-width: 396px !important;
  max-width: 396px !important;
}
#cover-right-widget .crw-height-404, #cover-right-widget .crw-h-404 {
  height: 404px !important;
  min-height: 404px !important;
  max-height: 404px !important;
}
#cover-right-widget .crw-width-404, #cover-right-widget .crw-w-404 {
  width: 404px !important;
  min-width: 404px !important;
  max-width: 404px !important;
}
#cover-right-widget .crw-height-408, #cover-right-widget .crw-h-408 {
  height: 408px !important;
  min-height: 408px !important;
  max-height: 408px !important;
}
#cover-right-widget .crw-width-408, #cover-right-widget .crw-w-408 {
  width: 408px !important;
  min-width: 408px !important;
  max-width: 408px !important;
}
#cover-right-widget .crw-height-412, #cover-right-widget .crw-h-412 {
  height: 412px !important;
  min-height: 412px !important;
  max-height: 412px !important;
}
#cover-right-widget .crw-width-412, #cover-right-widget .crw-w-412 {
  width: 412px !important;
  min-width: 412px !important;
  max-width: 412px !important;
}
#cover-right-widget .crw-height-416, #cover-right-widget .crw-h-416 {
  height: 416px !important;
  min-height: 416px !important;
  max-height: 416px !important;
}
#cover-right-widget .crw-width-416, #cover-right-widget .crw-w-416 {
  width: 416px !important;
  min-width: 416px !important;
  max-width: 416px !important;
}
#cover-right-widget .crw-height-424, #cover-right-widget .crw-h-424 {
  height: 424px !important;
  min-height: 424px !important;
  max-height: 424px !important;
}
#cover-right-widget .crw-width-424, #cover-right-widget .crw-w-424 {
  width: 424px !important;
  min-width: 424px !important;
  max-width: 424px !important;
}
#cover-right-widget .crw-height-428, #cover-right-widget .crw-h-428 {
  height: 428px !important;
  min-height: 428px !important;
  max-height: 428px !important;
}
#cover-right-widget .crw-width-428, #cover-right-widget .crw-w-428 {
  width: 428px !important;
  min-width: 428px !important;
  max-width: 428px !important;
}
#cover-right-widget .crw-height-432, #cover-right-widget .crw-h-432 {
  height: 432px !important;
  min-height: 432px !important;
  max-height: 432px !important;
}
#cover-right-widget .crw-width-432, #cover-right-widget .crw-w-432 {
  width: 432px !important;
  min-width: 432px !important;
  max-width: 432px !important;
}
#cover-right-widget .crw-height-436, #cover-right-widget .crw-h-436 {
  height: 436px !important;
  min-height: 436px !important;
  max-height: 436px !important;
}
#cover-right-widget .crw-width-436, #cover-right-widget .crw-w-436 {
  width: 436px !important;
  min-width: 436px !important;
  max-width: 436px !important;
}
#cover-right-widget .crw-height-444, #cover-right-widget .crw-h-444 {
  height: 444px !important;
  min-height: 444px !important;
  max-height: 444px !important;
}
#cover-right-widget .crw-width-444, #cover-right-widget .crw-w-444 {
  width: 444px !important;
  min-width: 444px !important;
  max-width: 444px !important;
}
#cover-right-widget .crw-height-448, #cover-right-widget .crw-h-448 {
  height: 448px !important;
  min-height: 448px !important;
  max-height: 448px !important;
}
#cover-right-widget .crw-width-448, #cover-right-widget .crw-w-448 {
  width: 448px !important;
  min-width: 448px !important;
  max-width: 448px !important;
}
#cover-right-widget .crw-height-452, #cover-right-widget .crw-h-452 {
  height: 452px !important;
  min-height: 452px !important;
  max-height: 452px !important;
}
#cover-right-widget .crw-width-452, #cover-right-widget .crw-w-452 {
  width: 452px !important;
  min-width: 452px !important;
  max-width: 452px !important;
}
#cover-right-widget .crw-height-456, #cover-right-widget .crw-h-456 {
  height: 456px !important;
  min-height: 456px !important;
  max-height: 456px !important;
}
#cover-right-widget .crw-width-456, #cover-right-widget .crw-w-456 {
  width: 456px !important;
  min-width: 456px !important;
  max-width: 456px !important;
}
#cover-right-widget .crw-height-464, #cover-right-widget .crw-h-464 {
  height: 464px !important;
  min-height: 464px !important;
  max-height: 464px !important;
}
#cover-right-widget .crw-width-464, #cover-right-widget .crw-w-464 {
  width: 464px !important;
  min-width: 464px !important;
  max-width: 464px !important;
}
#cover-right-widget .crw-height-468, #cover-right-widget .crw-h-468 {
  height: 468px !important;
  min-height: 468px !important;
  max-height: 468px !important;
}
#cover-right-widget .crw-width-468, #cover-right-widget .crw-w-468 {
  width: 468px !important;
  min-width: 468px !important;
  max-width: 468px !important;
}
#cover-right-widget .crw-height-472, #cover-right-widget .crw-h-472 {
  height: 472px !important;
  min-height: 472px !important;
  max-height: 472px !important;
}
#cover-right-widget .crw-width-472, #cover-right-widget .crw-w-472 {
  width: 472px !important;
  min-width: 472px !important;
  max-width: 472px !important;
}
#cover-right-widget .crw-height-476, #cover-right-widget .crw-h-476 {
  height: 476px !important;
  min-height: 476px !important;
  max-height: 476px !important;
}
#cover-right-widget .crw-width-476, #cover-right-widget .crw-w-476 {
  width: 476px !important;
  min-width: 476px !important;
  max-width: 476px !important;
}
#cover-right-widget .crw-height-484, #cover-right-widget .crw-h-484 {
  height: 484px !important;
  min-height: 484px !important;
  max-height: 484px !important;
}
#cover-right-widget .crw-width-484, #cover-right-widget .crw-w-484 {
  width: 484px !important;
  min-width: 484px !important;
  max-width: 484px !important;
}
#cover-right-widget .crw-height-488, #cover-right-widget .crw-h-488 {
  height: 488px !important;
  min-height: 488px !important;
  max-height: 488px !important;
}
#cover-right-widget .crw-width-488, #cover-right-widget .crw-w-488 {
  width: 488px !important;
  min-width: 488px !important;
  max-width: 488px !important;
}
#cover-right-widget .crw-height-492, #cover-right-widget .crw-h-492 {
  height: 492px !important;
  min-height: 492px !important;
  max-height: 492px !important;
}
#cover-right-widget .crw-width-492, #cover-right-widget .crw-w-492 {
  width: 492px !important;
  min-width: 492px !important;
  max-width: 492px !important;
}
#cover-right-widget .crw-height-496, #cover-right-widget .crw-h-496 {
  height: 496px !important;
  min-height: 496px !important;
  max-height: 496px !important;
}
#cover-right-widget .crw-width-496, #cover-right-widget .crw-w-496 {
  width: 496px !important;
  min-width: 496px !important;
  max-width: 496px !important;
}
#cover-right-widget .crw-height-504, #cover-right-widget .crw-h-504 {
  height: 504px !important;
  min-height: 504px !important;
  max-height: 504px !important;
}
#cover-right-widget .crw-width-504, #cover-right-widget .crw-w-504 {
  width: 504px !important;
  min-width: 504px !important;
  max-width: 504px !important;
}
#cover-right-widget .crw-height-508, #cover-right-widget .crw-h-508 {
  height: 508px !important;
  min-height: 508px !important;
  max-height: 508px !important;
}
#cover-right-widget .crw-width-508, #cover-right-widget .crw-w-508 {
  width: 508px !important;
  min-width: 508px !important;
  max-width: 508px !important;
}
#cover-right-widget .crw-height-512, #cover-right-widget .crw-h-512 {
  height: 512px !important;
  min-height: 512px !important;
  max-height: 512px !important;
}
#cover-right-widget .crw-width-512, #cover-right-widget .crw-w-512 {
  width: 512px !important;
  min-width: 512px !important;
  max-width: 512px !important;
}
#cover-right-widget .crw-height-516, #cover-right-widget .crw-h-516 {
  height: 516px !important;
  min-height: 516px !important;
  max-height: 516px !important;
}
#cover-right-widget .crw-width-516, #cover-right-widget .crw-w-516 {
  width: 516px !important;
  min-width: 516px !important;
  max-width: 516px !important;
}
#cover-right-widget .crw-height-524, #cover-right-widget .crw-h-524 {
  height: 524px !important;
  min-height: 524px !important;
  max-height: 524px !important;
}
#cover-right-widget .crw-width-524, #cover-right-widget .crw-w-524 {
  width: 524px !important;
  min-width: 524px !important;
  max-width: 524px !important;
}
#cover-right-widget .crw-height-528, #cover-right-widget .crw-h-528 {
  height: 528px !important;
  min-height: 528px !important;
  max-height: 528px !important;
}
#cover-right-widget .crw-width-528, #cover-right-widget .crw-w-528 {
  width: 528px !important;
  min-width: 528px !important;
  max-width: 528px !important;
}
#cover-right-widget .crw-height-532, #cover-right-widget .crw-h-532 {
  height: 532px !important;
  min-height: 532px !important;
  max-height: 532px !important;
}
#cover-right-widget .crw-width-532, #cover-right-widget .crw-w-532 {
  width: 532px !important;
  min-width: 532px !important;
  max-width: 532px !important;
}
#cover-right-widget .crw-height-536, #cover-right-widget .crw-h-536 {
  height: 536px !important;
  min-height: 536px !important;
  max-height: 536px !important;
}
#cover-right-widget .crw-width-536, #cover-right-widget .crw-w-536 {
  width: 536px !important;
  min-width: 536px !important;
  max-width: 536px !important;
}
#cover-right-widget .crw-height-544, #cover-right-widget .crw-h-544 {
  height: 544px !important;
  min-height: 544px !important;
  max-height: 544px !important;
}
#cover-right-widget .crw-width-544, #cover-right-widget .crw-w-544 {
  width: 544px !important;
  min-width: 544px !important;
  max-width: 544px !important;
}
#cover-right-widget .crw-height-548, #cover-right-widget .crw-h-548 {
  height: 548px !important;
  min-height: 548px !important;
  max-height: 548px !important;
}
#cover-right-widget .crw-width-548, #cover-right-widget .crw-w-548 {
  width: 548px !important;
  min-width: 548px !important;
  max-width: 548px !important;
}
#cover-right-widget .crw-height-552, #cover-right-widget .crw-h-552 {
  height: 552px !important;
  min-height: 552px !important;
  max-height: 552px !important;
}
#cover-right-widget .crw-width-552, #cover-right-widget .crw-w-552 {
  width: 552px !important;
  min-width: 552px !important;
  max-width: 552px !important;
}
#cover-right-widget .crw-height-556, #cover-right-widget .crw-h-556 {
  height: 556px !important;
  min-height: 556px !important;
  max-height: 556px !important;
}
#cover-right-widget .crw-width-556, #cover-right-widget .crw-w-556 {
  width: 556px !important;
  min-width: 556px !important;
  max-width: 556px !important;
}
#cover-right-widget .crw-height-564, #cover-right-widget .crw-h-564 {
  height: 564px !important;
  min-height: 564px !important;
  max-height: 564px !important;
}
#cover-right-widget .crw-width-564, #cover-right-widget .crw-w-564 {
  width: 564px !important;
  min-width: 564px !important;
  max-width: 564px !important;
}
#cover-right-widget .crw-height-568, #cover-right-widget .crw-h-568 {
  height: 568px !important;
  min-height: 568px !important;
  max-height: 568px !important;
}
#cover-right-widget .crw-width-568, #cover-right-widget .crw-w-568 {
  width: 568px !important;
  min-width: 568px !important;
  max-width: 568px !important;
}
#cover-right-widget .crw-height-572, #cover-right-widget .crw-h-572 {
  height: 572px !important;
  min-height: 572px !important;
  max-height: 572px !important;
}
#cover-right-widget .crw-width-572, #cover-right-widget .crw-w-572 {
  width: 572px !important;
  min-width: 572px !important;
  max-width: 572px !important;
}
#cover-right-widget .crw-height-576, #cover-right-widget .crw-h-576 {
  height: 576px !important;
  min-height: 576px !important;
  max-height: 576px !important;
}
#cover-right-widget .crw-width-576, #cover-right-widget .crw-w-576 {
  width: 576px !important;
  min-width: 576px !important;
  max-width: 576px !important;
}
#cover-right-widget .crw-height-584, #cover-right-widget .crw-h-584 {
  height: 584px !important;
  min-height: 584px !important;
  max-height: 584px !important;
}
#cover-right-widget .crw-width-584, #cover-right-widget .crw-w-584 {
  width: 584px !important;
  min-width: 584px !important;
  max-width: 584px !important;
}
#cover-right-widget .crw-height-588, #cover-right-widget .crw-h-588 {
  height: 588px !important;
  min-height: 588px !important;
  max-height: 588px !important;
}
#cover-right-widget .crw-width-588, #cover-right-widget .crw-w-588 {
  width: 588px !important;
  min-width: 588px !important;
  max-width: 588px !important;
}
#cover-right-widget .crw-height-592, #cover-right-widget .crw-h-592 {
  height: 592px !important;
  min-height: 592px !important;
  max-height: 592px !important;
}
#cover-right-widget .crw-width-592, #cover-right-widget .crw-w-592 {
  width: 592px !important;
  min-width: 592px !important;
  max-width: 592px !important;
}
#cover-right-widget .crw-height-596, #cover-right-widget .crw-h-596 {
  height: 596px !important;
  min-height: 596px !important;
  max-height: 596px !important;
}
#cover-right-widget .crw-width-596, #cover-right-widget .crw-w-596 {
  width: 596px !important;
  min-width: 596px !important;
  max-width: 596px !important;
}
#cover-right-widget .crw-height-604, #cover-right-widget .crw-h-604 {
  height: 604px !important;
  min-height: 604px !important;
  max-height: 604px !important;
}
#cover-right-widget .crw-width-604, #cover-right-widget .crw-w-604 {
  width: 604px !important;
  min-width: 604px !important;
  max-width: 604px !important;
}
#cover-right-widget .crw-height-608, #cover-right-widget .crw-h-608 {
  height: 608px !important;
  min-height: 608px !important;
  max-height: 608px !important;
}
#cover-right-widget .crw-width-608, #cover-right-widget .crw-w-608 {
  width: 608px !important;
  min-width: 608px !important;
  max-width: 608px !important;
}
#cover-right-widget .crw-height-612, #cover-right-widget .crw-h-612 {
  height: 612px !important;
  min-height: 612px !important;
  max-height: 612px !important;
}
#cover-right-widget .crw-width-612, #cover-right-widget .crw-w-612 {
  width: 612px !important;
  min-width: 612px !important;
  max-width: 612px !important;
}
#cover-right-widget .crw-height-616, #cover-right-widget .crw-h-616 {
  height: 616px !important;
  min-height: 616px !important;
  max-height: 616px !important;
}
#cover-right-widget .crw-width-616, #cover-right-widget .crw-w-616 {
  width: 616px !important;
  min-width: 616px !important;
  max-width: 616px !important;
}
#cover-right-widget .crw-height-624, #cover-right-widget .crw-h-624 {
  height: 624px !important;
  min-height: 624px !important;
  max-height: 624px !important;
}
#cover-right-widget .crw-width-624, #cover-right-widget .crw-w-624 {
  width: 624px !important;
  min-width: 624px !important;
  max-width: 624px !important;
}
#cover-right-widget .crw-height-628, #cover-right-widget .crw-h-628 {
  height: 628px !important;
  min-height: 628px !important;
  max-height: 628px !important;
}
#cover-right-widget .crw-width-628, #cover-right-widget .crw-w-628 {
  width: 628px !important;
  min-width: 628px !important;
  max-width: 628px !important;
}
#cover-right-widget .crw-height-632, #cover-right-widget .crw-h-632 {
  height: 632px !important;
  min-height: 632px !important;
  max-height: 632px !important;
}
#cover-right-widget .crw-width-632, #cover-right-widget .crw-w-632 {
  width: 632px !important;
  min-width: 632px !important;
  max-width: 632px !important;
}
#cover-right-widget .crw-height-636, #cover-right-widget .crw-h-636 {
  height: 636px !important;
  min-height: 636px !important;
  max-height: 636px !important;
}
#cover-right-widget .crw-width-636, #cover-right-widget .crw-w-636 {
  width: 636px !important;
  min-width: 636px !important;
  max-width: 636px !important;
}
#cover-right-widget .crw-height-644, #cover-right-widget .crw-h-644 {
  height: 644px !important;
  min-height: 644px !important;
  max-height: 644px !important;
}
#cover-right-widget .crw-width-644, #cover-right-widget .crw-w-644 {
  width: 644px !important;
  min-width: 644px !important;
  max-width: 644px !important;
}
#cover-right-widget .crw-height-648, #cover-right-widget .crw-h-648 {
  height: 648px !important;
  min-height: 648px !important;
  max-height: 648px !important;
}
#cover-right-widget .crw-width-648, #cover-right-widget .crw-w-648 {
  width: 648px !important;
  min-width: 648px !important;
  max-width: 648px !important;
}
#cover-right-widget .crw-height-652, #cover-right-widget .crw-h-652 {
  height: 652px !important;
  min-height: 652px !important;
  max-height: 652px !important;
}
#cover-right-widget .crw-width-652, #cover-right-widget .crw-w-652 {
  width: 652px !important;
  min-width: 652px !important;
  max-width: 652px !important;
}
#cover-right-widget .crw-height-656, #cover-right-widget .crw-h-656 {
  height: 656px !important;
  min-height: 656px !important;
  max-height: 656px !important;
}
#cover-right-widget .crw-width-656, #cover-right-widget .crw-w-656 {
  width: 656px !important;
  min-width: 656px !important;
  max-width: 656px !important;
}
#cover-right-widget .crw-height-664, #cover-right-widget .crw-h-664 {
  height: 664px !important;
  min-height: 664px !important;
  max-height: 664px !important;
}
#cover-right-widget .crw-width-664, #cover-right-widget .crw-w-664 {
  width: 664px !important;
  min-width: 664px !important;
  max-width: 664px !important;
}
#cover-right-widget .crw-height-668, #cover-right-widget .crw-h-668 {
  height: 668px !important;
  min-height: 668px !important;
  max-height: 668px !important;
}
#cover-right-widget .crw-width-668, #cover-right-widget .crw-w-668 {
  width: 668px !important;
  min-width: 668px !important;
  max-width: 668px !important;
}
#cover-right-widget .crw-height-672, #cover-right-widget .crw-h-672 {
  height: 672px !important;
  min-height: 672px !important;
  max-height: 672px !important;
}
#cover-right-widget .crw-width-672, #cover-right-widget .crw-w-672 {
  width: 672px !important;
  min-width: 672px !important;
  max-width: 672px !important;
}
#cover-right-widget .crw-height-676, #cover-right-widget .crw-h-676 {
  height: 676px !important;
  min-height: 676px !important;
  max-height: 676px !important;
}
#cover-right-widget .crw-width-676, #cover-right-widget .crw-w-676 {
  width: 676px !important;
  min-width: 676px !important;
  max-width: 676px !important;
}
#cover-right-widget .crw-height-684, #cover-right-widget .crw-h-684 {
  height: 684px !important;
  min-height: 684px !important;
  max-height: 684px !important;
}
#cover-right-widget .crw-width-684, #cover-right-widget .crw-w-684 {
  width: 684px !important;
  min-width: 684px !important;
  max-width: 684px !important;
}
#cover-right-widget .crw-height-688, #cover-right-widget .crw-h-688 {
  height: 688px !important;
  min-height: 688px !important;
  max-height: 688px !important;
}
#cover-right-widget .crw-width-688, #cover-right-widget .crw-w-688 {
  width: 688px !important;
  min-width: 688px !important;
  max-width: 688px !important;
}
#cover-right-widget .crw-height-692, #cover-right-widget .crw-h-692 {
  height: 692px !important;
  min-height: 692px !important;
  max-height: 692px !important;
}
#cover-right-widget .crw-width-692, #cover-right-widget .crw-w-692 {
  width: 692px !important;
  min-width: 692px !important;
  max-width: 692px !important;
}
#cover-right-widget .crw-height-696, #cover-right-widget .crw-h-696 {
  height: 696px !important;
  min-height: 696px !important;
  max-height: 696px !important;
}
#cover-right-widget .crw-width-696, #cover-right-widget .crw-w-696 {
  width: 696px !important;
  min-width: 696px !important;
  max-width: 696px !important;
}
#cover-right-widget .crw-height-704, #cover-right-widget .crw-h-704 {
  height: 704px !important;
  min-height: 704px !important;
  max-height: 704px !important;
}
#cover-right-widget .crw-width-704, #cover-right-widget .crw-w-704 {
  width: 704px !important;
  min-width: 704px !important;
  max-width: 704px !important;
}
#cover-right-widget .crw-height-708, #cover-right-widget .crw-h-708 {
  height: 708px !important;
  min-height: 708px !important;
  max-height: 708px !important;
}
#cover-right-widget .crw-width-708, #cover-right-widget .crw-w-708 {
  width: 708px !important;
  min-width: 708px !important;
  max-width: 708px !important;
}
#cover-right-widget .crw-height-712, #cover-right-widget .crw-h-712 {
  height: 712px !important;
  min-height: 712px !important;
  max-height: 712px !important;
}
#cover-right-widget .crw-width-712, #cover-right-widget .crw-w-712 {
  width: 712px !important;
  min-width: 712px !important;
  max-width: 712px !important;
}
#cover-right-widget .crw-height-716, #cover-right-widget .crw-h-716 {
  height: 716px !important;
  min-height: 716px !important;
  max-height: 716px !important;
}
#cover-right-widget .crw-width-716, #cover-right-widget .crw-w-716 {
  width: 716px !important;
  min-width: 716px !important;
  max-width: 716px !important;
}
#cover-right-widget .crw-height-724, #cover-right-widget .crw-h-724 {
  height: 724px !important;
  min-height: 724px !important;
  max-height: 724px !important;
}
#cover-right-widget .crw-width-724, #cover-right-widget .crw-w-724 {
  width: 724px !important;
  min-width: 724px !important;
  max-width: 724px !important;
}
#cover-right-widget .crw-height-728, #cover-right-widget .crw-h-728 {
  height: 728px !important;
  min-height: 728px !important;
  max-height: 728px !important;
}
#cover-right-widget .crw-width-728, #cover-right-widget .crw-w-728 {
  width: 728px !important;
  min-width: 728px !important;
  max-width: 728px !important;
}
#cover-right-widget .crw-height-732, #cover-right-widget .crw-h-732 {
  height: 732px !important;
  min-height: 732px !important;
  max-height: 732px !important;
}
#cover-right-widget .crw-width-732, #cover-right-widget .crw-w-732 {
  width: 732px !important;
  min-width: 732px !important;
  max-width: 732px !important;
}
#cover-right-widget .crw-height-736, #cover-right-widget .crw-h-736 {
  height: 736px !important;
  min-height: 736px !important;
  max-height: 736px !important;
}
#cover-right-widget .crw-width-736, #cover-right-widget .crw-w-736 {
  width: 736px !important;
  min-width: 736px !important;
  max-width: 736px !important;
}
#cover-right-widget .crw-height-744, #cover-right-widget .crw-h-744 {
  height: 744px !important;
  min-height: 744px !important;
  max-height: 744px !important;
}
#cover-right-widget .crw-width-744, #cover-right-widget .crw-w-744 {
  width: 744px !important;
  min-width: 744px !important;
  max-width: 744px !important;
}
#cover-right-widget .crw-height-748, #cover-right-widget .crw-h-748 {
  height: 748px !important;
  min-height: 748px !important;
  max-height: 748px !important;
}
#cover-right-widget .crw-width-748, #cover-right-widget .crw-w-748 {
  width: 748px !important;
  min-width: 748px !important;
  max-width: 748px !important;
}
#cover-right-widget .crw-height-752, #cover-right-widget .crw-h-752 {
  height: 752px !important;
  min-height: 752px !important;
  max-height: 752px !important;
}
#cover-right-widget .crw-width-752, #cover-right-widget .crw-w-752 {
  width: 752px !important;
  min-width: 752px !important;
  max-width: 752px !important;
}
#cover-right-widget .crw-height-756, #cover-right-widget .crw-h-756 {
  height: 756px !important;
  min-height: 756px !important;
  max-height: 756px !important;
}
#cover-right-widget .crw-width-756, #cover-right-widget .crw-w-756 {
  width: 756px !important;
  min-width: 756px !important;
  max-width: 756px !important;
}
#cover-right-widget .crw-height-764, #cover-right-widget .crw-h-764 {
  height: 764px !important;
  min-height: 764px !important;
  max-height: 764px !important;
}
#cover-right-widget .crw-width-764, #cover-right-widget .crw-w-764 {
  width: 764px !important;
  min-width: 764px !important;
  max-width: 764px !important;
}
#cover-right-widget .crw-height-768, #cover-right-widget .crw-h-768 {
  height: 768px !important;
  min-height: 768px !important;
  max-height: 768px !important;
}
#cover-right-widget .crw-width-768, #cover-right-widget .crw-w-768 {
  width: 768px !important;
  min-width: 768px !important;
  max-width: 768px !important;
}
#cover-right-widget .crw-height-772, #cover-right-widget .crw-h-772 {
  height: 772px !important;
  min-height: 772px !important;
  max-height: 772px !important;
}
#cover-right-widget .crw-width-772, #cover-right-widget .crw-w-772 {
  width: 772px !important;
  min-width: 772px !important;
  max-width: 772px !important;
}
#cover-right-widget .crw-height-776, #cover-right-widget .crw-h-776 {
  height: 776px !important;
  min-height: 776px !important;
  max-height: 776px !important;
}
#cover-right-widget .crw-width-776, #cover-right-widget .crw-w-776 {
  width: 776px !important;
  min-width: 776px !important;
  max-width: 776px !important;
}
#cover-right-widget .crw-height-784, #cover-right-widget .crw-h-784 {
  height: 784px !important;
  min-height: 784px !important;
  max-height: 784px !important;
}
#cover-right-widget .crw-width-784, #cover-right-widget .crw-w-784 {
  width: 784px !important;
  min-width: 784px !important;
  max-width: 784px !important;
}
#cover-right-widget .crw-height-788, #cover-right-widget .crw-h-788 {
  height: 788px !important;
  min-height: 788px !important;
  max-height: 788px !important;
}
#cover-right-widget .crw-width-788, #cover-right-widget .crw-w-788 {
  width: 788px !important;
  min-width: 788px !important;
  max-width: 788px !important;
}
#cover-right-widget .crw-height-792, #cover-right-widget .crw-h-792 {
  height: 792px !important;
  min-height: 792px !important;
  max-height: 792px !important;
}
#cover-right-widget .crw-width-792, #cover-right-widget .crw-w-792 {
  width: 792px !important;
  min-width: 792px !important;
  max-width: 792px !important;
}
#cover-right-widget .crw-height-796, #cover-right-widget .crw-h-796 {
  height: 796px !important;
  min-height: 796px !important;
  max-height: 796px !important;
}
#cover-right-widget .crw-width-796, #cover-right-widget .crw-w-796 {
  width: 796px !important;
  min-width: 796px !important;
  max-width: 796px !important;
}
#cover-right-widget .crw-font-size-1, #cover-right-widget .crw-fs-1 {
  font-size: 1px !important;
}
#cover-right-widget .crw-font-size-2, #cover-right-widget .crw-fs-2 {
  font-size: 2px !important;
}
#cover-right-widget .crw-font-size-3, #cover-right-widget .crw-fs-3 {
  font-size: 3px !important;
}
#cover-right-widget .crw-font-size-4, #cover-right-widget .crw-fs-4 {
  font-size: 4px !important;
}
#cover-right-widget .crw-font-size-5, #cover-right-widget .crw-fs-5 {
  font-size: 5px !important;
}
#cover-right-widget .crw-font-size-6, #cover-right-widget .crw-fs-6 {
  font-size: 6px !important;
}
#cover-right-widget .crw-font-size-7, #cover-right-widget .crw-fs-7 {
  font-size: 7px !important;
}
#cover-right-widget .crw-font-size-8, #cover-right-widget .crw-fs-8 {
  font-size: 8px !important;
}
#cover-right-widget .crw-font-size-9, #cover-right-widget .crw-fs-9 {
  font-size: 9px !important;
}
#cover-right-widget .crw-font-size-10, #cover-right-widget .crw-fs-10 {
  font-size: 10px !important;
}
#cover-right-widget .crw-font-size-11, #cover-right-widget .crw-fs-11 {
  font-size: 11px !important;
}
#cover-right-widget .crw-font-size-12, #cover-right-widget .crw-fs-12 {
  font-size: 12px !important;
}
#cover-right-widget .crw-font-size-13, #cover-right-widget .crw-fs-13 {
  font-size: 13px !important;
}
#cover-right-widget .crw-font-size-14, #cover-right-widget .crw-fs-14 {
  font-size: 14px !important;
}
#cover-right-widget .crw-font-size-15, #cover-right-widget .crw-fs-15 {
  font-size: 15px !important;
}
#cover-right-widget .crw-font-size-16, #cover-right-widget .crw-fs-16 {
  font-size: 16px !important;
}
#cover-right-widget .crw-font-size-17, #cover-right-widget .crw-fs-17 {
  font-size: 17px !important;
}
#cover-right-widget .crw-font-size-18, #cover-right-widget .crw-fs-18 {
  font-size: 18px !important;
}
#cover-right-widget .crw-font-size-19, #cover-right-widget .crw-fs-19 {
  font-size: 19px !important;
}
#cover-right-widget .crw-font-size-20, #cover-right-widget .crw-fs-20 {
  font-size: 20px !important;
}
#cover-right-widget .crw-font-size-21, #cover-right-widget .crw-fs-21 {
  font-size: 21px !important;
}
#cover-right-widget .crw-font-size-22, #cover-right-widget .crw-fs-22 {
  font-size: 22px !important;
}
#cover-right-widget .crw-font-size-23, #cover-right-widget .crw-fs-23 {
  font-size: 23px !important;
}
#cover-right-widget .crw-font-size-24, #cover-right-widget .crw-fs-24 {
  font-size: 24px !important;
}
#cover-right-widget .crw-font-size-25, #cover-right-widget .crw-fs-25 {
  font-size: 25px !important;
}
#cover-right-widget .crw-font-size-26, #cover-right-widget .crw-fs-26 {
  font-size: 26px !important;
}
#cover-right-widget .crw-font-size-27, #cover-right-widget .crw-fs-27 {
  font-size: 27px !important;
}
#cover-right-widget .crw-font-size-28, #cover-right-widget .crw-fs-28 {
  font-size: 28px !important;
}
#cover-right-widget .crw-font-size-29, #cover-right-widget .crw-fs-29 {
  font-size: 29px !important;
}
#cover-right-widget .crw-font-size-30, #cover-right-widget .crw-fs-30 {
  font-size: 30px !important;
}
#cover-right-widget .crw-font-size-31, #cover-right-widget .crw-fs-31 {
  font-size: 31px !important;
}
#cover-right-widget .crw-font-size-32, #cover-right-widget .crw-fs-32 {
  font-size: 32px !important;
}
#cover-right-widget .crw-font-size-33, #cover-right-widget .crw-fs-33 {
  font-size: 33px !important;
}
#cover-right-widget .crw-font-size-34, #cover-right-widget .crw-fs-34 {
  font-size: 34px !important;
}
#cover-right-widget .crw-font-size-35, #cover-right-widget .crw-fs-35 {
  font-size: 35px !important;
}
#cover-right-widget .crw-font-size-36, #cover-right-widget .crw-fs-36 {
  font-size: 36px !important;
}
#cover-right-widget .crw-font-size-37, #cover-right-widget .crw-fs-37 {
  font-size: 37px !important;
}
#cover-right-widget .crw-font-size-38, #cover-right-widget .crw-fs-38 {
  font-size: 38px !important;
}
#cover-right-widget .crw-font-size-39, #cover-right-widget .crw-fs-39 {
  font-size: 39px !important;
}
#cover-right-widget .crw-font-size-40, #cover-right-widget .crw-fs-40 {
  font-size: 40px !important;
}
#cover-right-widget .crw-font-size-41, #cover-right-widget .crw-fs-41 {
  font-size: 41px !important;
}
#cover-right-widget .crw-font-size-42, #cover-right-widget .crw-fs-42 {
  font-size: 42px !important;
}
#cover-right-widget .crw-font-size-43, #cover-right-widget .crw-fs-43 {
  font-size: 43px !important;
}
#cover-right-widget .crw-font-size-44, #cover-right-widget .crw-fs-44 {
  font-size: 44px !important;
}
#cover-right-widget .crw-font-size-45, #cover-right-widget .crw-fs-45 {
  font-size: 45px !important;
}
#cover-right-widget .crw-font-size-46, #cover-right-widget .crw-fs-46 {
  font-size: 46px !important;
}
#cover-right-widget .crw-font-size-47, #cover-right-widget .crw-fs-47 {
  font-size: 47px !important;
}
#cover-right-widget .crw-font-size-48, #cover-right-widget .crw-fs-48 {
  font-size: 48px !important;
}
#cover-right-widget .crw-font-size-49, #cover-right-widget .crw-fs-49 {
  font-size: 49px !important;
}
#cover-right-widget .crw-font-size-50, #cover-right-widget .crw-fs-50 {
  font-size: 50px !important;
}
#cover-right-widget .crw-font-size-51, #cover-right-widget .crw-fs-51 {
  font-size: 51px !important;
}
#cover-right-widget .crw-font-size-52, #cover-right-widget .crw-fs-52 {
  font-size: 52px !important;
}
#cover-right-widget .crw-font-size-53, #cover-right-widget .crw-fs-53 {
  font-size: 53px !important;
}
#cover-right-widget .crw-font-size-54, #cover-right-widget .crw-fs-54 {
  font-size: 54px !important;
}
#cover-right-widget .crw-font-size-55, #cover-right-widget .crw-fs-55 {
  font-size: 55px !important;
}
#cover-right-widget .crw-font-size-56, #cover-right-widget .crw-fs-56 {
  font-size: 56px !important;
}
#cover-right-widget .crw-font-size-57, #cover-right-widget .crw-fs-57 {
  font-size: 57px !important;
}
#cover-right-widget .crw-font-size-58, #cover-right-widget .crw-fs-58 {
  font-size: 58px !important;
}
#cover-right-widget .crw-font-size-59, #cover-right-widget .crw-fs-59 {
  font-size: 59px !important;
}
#cover-right-widget .crw-font-size-60, #cover-right-widget .crw-fs-60 {
  font-size: 60px !important;
}
#cover-right-widget .crw-font-size-61, #cover-right-widget .crw-fs-61 {
  font-size: 61px !important;
}
#cover-right-widget .crw-font-size-62, #cover-right-widget .crw-fs-62 {
  font-size: 62px !important;
}
#cover-right-widget .crw-font-size-63, #cover-right-widget .crw-fs-63 {
  font-size: 63px !important;
}
#cover-right-widget .crw-font-size-64, #cover-right-widget .crw-fs-64 {
  font-size: 64px !important;
}
#cover-right-widget .crw-font-size-65, #cover-right-widget .crw-fs-65 {
  font-size: 65px !important;
}
#cover-right-widget .crw-font-size-66, #cover-right-widget .crw-fs-66 {
  font-size: 66px !important;
}
#cover-right-widget .crw-font-size-67, #cover-right-widget .crw-fs-67 {
  font-size: 67px !important;
}
#cover-right-widget .crw-font-size-68, #cover-right-widget .crw-fs-68 {
  font-size: 68px !important;
}
#cover-right-widget .crw-font-size-69, #cover-right-widget .crw-fs-69 {
  font-size: 69px !important;
}
#cover-right-widget .crw-font-size-70, #cover-right-widget .crw-fs-70 {
  font-size: 70px !important;
}
#cover-right-widget .crw-line-height-1, #cover-right-widget .crw-lh-1 {
  line-height: 1px !important;
}
#cover-right-widget .crw-line-height-2, #cover-right-widget .crw-lh-2 {
  line-height: 2px !important;
}
#cover-right-widget .crw-line-height-3, #cover-right-widget .crw-lh-3 {
  line-height: 3px !important;
}
#cover-right-widget .crw-line-height-4, #cover-right-widget .crw-lh-4 {
  line-height: 4px !important;
}
#cover-right-widget .crw-line-height-5, #cover-right-widget .crw-lh-5 {
  line-height: 5px !important;
}
#cover-right-widget .crw-line-height-6, #cover-right-widget .crw-lh-6 {
  line-height: 6px !important;
}
#cover-right-widget .crw-line-height-7, #cover-right-widget .crw-lh-7 {
  line-height: 7px !important;
}
#cover-right-widget .crw-line-height-8, #cover-right-widget .crw-lh-8 {
  line-height: 8px !important;
}
#cover-right-widget .crw-line-height-9, #cover-right-widget .crw-lh-9 {
  line-height: 9px !important;
}
#cover-right-widget .crw-line-height-10, #cover-right-widget .crw-lh-10 {
  line-height: 10px !important;
}
#cover-right-widget .crw-line-height-11, #cover-right-widget .crw-lh-11 {
  line-height: 11px !important;
}
#cover-right-widget .crw-line-height-12, #cover-right-widget .crw-lh-12 {
  line-height: 12px !important;
}
#cover-right-widget .crw-line-height-13, #cover-right-widget .crw-lh-13 {
  line-height: 13px !important;
}
#cover-right-widget .crw-line-height-14, #cover-right-widget .crw-lh-14 {
  line-height: 14px !important;
}
#cover-right-widget .crw-line-height-15, #cover-right-widget .crw-lh-15 {
  line-height: 15px !important;
}
#cover-right-widget .crw-line-height-16, #cover-right-widget .crw-lh-16 {
  line-height: 16px !important;
}
#cover-right-widget .crw-line-height-17, #cover-right-widget .crw-lh-17 {
  line-height: 17px !important;
}
#cover-right-widget .crw-line-height-18, #cover-right-widget .crw-lh-18 {
  line-height: 18px !important;
}
#cover-right-widget .crw-line-height-19, #cover-right-widget .crw-lh-19 {
  line-height: 19px !important;
}
#cover-right-widget .crw-line-height-20, #cover-right-widget .crw-lh-20 {
  line-height: 20px !important;
}
#cover-right-widget .crw-line-height-21, #cover-right-widget .crw-lh-21 {
  line-height: 21px !important;
}
#cover-right-widget .crw-line-height-22, #cover-right-widget .crw-lh-22 {
  line-height: 22px !important;
}
#cover-right-widget .crw-line-height-23, #cover-right-widget .crw-lh-23 {
  line-height: 23px !important;
}
#cover-right-widget .crw-line-height-24, #cover-right-widget .crw-lh-24 {
  line-height: 24px !important;
}
#cover-right-widget .crw-line-height-25, #cover-right-widget .crw-lh-25 {
  line-height: 25px !important;
}
#cover-right-widget .crw-line-height-26, #cover-right-widget .crw-lh-26 {
  line-height: 26px !important;
}
#cover-right-widget .crw-line-height-27, #cover-right-widget .crw-lh-27 {
  line-height: 27px !important;
}
#cover-right-widget .crw-line-height-28, #cover-right-widget .crw-lh-28 {
  line-height: 28px !important;
}
#cover-right-widget .crw-line-height-29, #cover-right-widget .crw-lh-29 {
  line-height: 29px !important;
}
#cover-right-widget .crw-line-height-30, #cover-right-widget .crw-lh-30 {
  line-height: 30px !important;
}
#cover-right-widget .crw-line-height-31, #cover-right-widget .crw-lh-31 {
  line-height: 31px !important;
}
#cover-right-widget .crw-line-height-32, #cover-right-widget .crw-lh-32 {
  line-height: 32px !important;
}
#cover-right-widget .crw-line-height-33, #cover-right-widget .crw-lh-33 {
  line-height: 33px !important;
}
#cover-right-widget .crw-line-height-34, #cover-right-widget .crw-lh-34 {
  line-height: 34px !important;
}
#cover-right-widget .crw-line-height-35, #cover-right-widget .crw-lh-35 {
  line-height: 35px !important;
}
#cover-right-widget .crw-line-height-36, #cover-right-widget .crw-lh-36 {
  line-height: 36px !important;
}
#cover-right-widget .crw-line-height-37, #cover-right-widget .crw-lh-37 {
  line-height: 37px !important;
}
#cover-right-widget .crw-line-height-38, #cover-right-widget .crw-lh-38 {
  line-height: 38px !important;
}
#cover-right-widget .crw-line-height-39, #cover-right-widget .crw-lh-39 {
  line-height: 39px !important;
}
#cover-right-widget .crw-line-height-40, #cover-right-widget .crw-lh-40 {
  line-height: 40px !important;
}
#cover-right-widget .crw-line-height-41, #cover-right-widget .crw-lh-41 {
  line-height: 41px !important;
}
#cover-right-widget .crw-line-height-42, #cover-right-widget .crw-lh-42 {
  line-height: 42px !important;
}
#cover-right-widget .crw-line-height-43, #cover-right-widget .crw-lh-43 {
  line-height: 43px !important;
}
#cover-right-widget .crw-line-height-44, #cover-right-widget .crw-lh-44 {
  line-height: 44px !important;
}
#cover-right-widget .crw-line-height-45, #cover-right-widget .crw-lh-45 {
  line-height: 45px !important;
}
#cover-right-widget .crw-line-height-46, #cover-right-widget .crw-lh-46 {
  line-height: 46px !important;
}
#cover-right-widget .crw-line-height-47, #cover-right-widget .crw-lh-47 {
  line-height: 47px !important;
}
#cover-right-widget .crw-line-height-48, #cover-right-widget .crw-lh-48 {
  line-height: 48px !important;
}
#cover-right-widget .crw-line-height-49, #cover-right-widget .crw-lh-49 {
  line-height: 49px !important;
}
#cover-right-widget .crw-line-height-50, #cover-right-widget .crw-lh-50 {
  line-height: 50px !important;
}
#cover-right-widget .crw-line-height-51, #cover-right-widget .crw-lh-51 {
  line-height: 51px !important;
}
#cover-right-widget .crw-line-height-52, #cover-right-widget .crw-lh-52 {
  line-height: 52px !important;
}
#cover-right-widget .crw-line-height-53, #cover-right-widget .crw-lh-53 {
  line-height: 53px !important;
}
#cover-right-widget .crw-line-height-54, #cover-right-widget .crw-lh-54 {
  line-height: 54px !important;
}
#cover-right-widget .crw-line-height-55, #cover-right-widget .crw-lh-55 {
  line-height: 55px !important;
}
#cover-right-widget .crw-line-height-56, #cover-right-widget .crw-lh-56 {
  line-height: 56px !important;
}
#cover-right-widget .crw-line-height-57, #cover-right-widget .crw-lh-57 {
  line-height: 57px !important;
}
#cover-right-widget .crw-line-height-58, #cover-right-widget .crw-lh-58 {
  line-height: 58px !important;
}
#cover-right-widget .crw-line-height-59, #cover-right-widget .crw-lh-59 {
  line-height: 59px !important;
}
#cover-right-widget .crw-line-height-60, #cover-right-widget .crw-lh-60 {
  line-height: 60px !important;
}
#cover-right-widget .crw-line-height-61, #cover-right-widget .crw-lh-61 {
  line-height: 61px !important;
}
#cover-right-widget .crw-line-height-62, #cover-right-widget .crw-lh-62 {
  line-height: 62px !important;
}
#cover-right-widget .crw-line-height-63, #cover-right-widget .crw-lh-63 {
  line-height: 63px !important;
}
#cover-right-widget .crw-line-height-64, #cover-right-widget .crw-lh-64 {
  line-height: 64px !important;
}
#cover-right-widget .crw-line-height-65, #cover-right-widget .crw-lh-65 {
  line-height: 65px !important;
}
#cover-right-widget .crw-line-height-66, #cover-right-widget .crw-lh-66 {
  line-height: 66px !important;
}
#cover-right-widget .crw-line-height-67, #cover-right-widget .crw-lh-67 {
  line-height: 67px !important;
}
#cover-right-widget .crw-line-height-68, #cover-right-widget .crw-lh-68 {
  line-height: 68px !important;
}
#cover-right-widget .crw-line-height-69, #cover-right-widget .crw-lh-69 {
  line-height: 69px !important;
}
#cover-right-widget .crw-line-height-70, #cover-right-widget .crw-lh-70 {
  line-height: 70px !important;
}
#cover-right-widget .crw-border, #cover-right-widget .crw-b {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
#cover-right-widget .crw-border-top, #cover-right-widget .crw-bt {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
#cover-right-widget .crw-border-right, #cover-right-widget .crw-br {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
#cover-right-widget .crw-border-bottom, #cover-right-widget .crw-bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#cover-right-widget .crw-border-left, #cover-right-widget .crw-bl {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
#cover-right-widget .crw-border-horizontal, #cover-right-widget .crw-bh {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
#cover-right-widget .crw-border-vertical, #cover-right-widget .crw-bv {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#cover-right-widget .crw-brad-1 {
  border-radius: 1px;
}
#cover-right-widget .crw-brad-2 {
  border-radius: 2px;
}
#cover-right-widget .crw-brad-3 {
  border-radius: 3px;
}
#cover-right-widget .crw-brad-4 {
  border-radius: 4px;
}
#cover-right-widget .crw-brad-5 {
  border-radius: 5px;
}
#cover-right-widget .crw-brad-6 {
  border-radius: 6px;
}
#cover-right-widget .crw-brad-7 {
  border-radius: 7px;
}
#cover-right-widget .crw-brad-8 {
  border-radius: 8px;
}
#cover-right-widget .crw-brad-9 {
  border-radius: 9px;
}
#cover-right-widget .crw-brad-10 {
  border-radius: 10px;
}
#cover-right-widget .crw-brad-11 {
  border-radius: 11px;
}
#cover-right-widget .crw-brad-12 {
  border-radius: 12px;
}
#cover-right-widget .crw-relative {
  position: relative !important;
}
#cover-right-widget .crw-pointer {
  cursor: pointer;
}
#cover-right-widget .crw-underline {
  text-decoration: underline;
}
#cover-right-widget .crw-cursor-default {
  cursor: default;
}
#cover-right-widget .crw-flex {
  display: flex;
}
#cover-right-widget .crw-flex-align-center {
  align-items: center;
}
#cover-right-widget .crw-flex-align-end {
  align-items: flex-end;
}
#cover-right-widget .crw-flex-justify-end {
  justify-content: flex-end;
}
#cover-right-widget .crw-flex-justify-center {
  justify-content: center;
}
#cover-right-widget .crw-flex-align-start {
  align-items: flex-start !important;
}
#cover-right-widget .crw-flex-space-between {
  display: flex;
  justify-content: space-between;
}
#cover-right-widget .crw-flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
#cover-right-widget .crw-text-align-centered {
  text-align: center;
}
#cover-right-widget .crw-medium {
  font-weight: 500 !important;
}
#cover-right-widget .crw-bold {
  font-weight: 700 !important;
}
#cover-right-widget .crw-thin {
  font-weight: 400 !important;
}
#cover-right-widget .crw-green {
  color: #5ecb9e;
}
#cover-right-widget .crw-dark-green {
  color: #029094;
}
#cover-right-widget .crw-color-primary {
  color: #1c434f;
}
#cover-right-widget .crw-no-wrap {
  white-space: nowrap;
}
#cover-right-widget .crw-no-decoration {
  text-decoration: none !important;
}
#cover-right-widget .crw-display-none {
  display: none !important;
}
#cover-right-widget .crw-bg-white {
  background-color: white;
}
#cover-right-widget .crw-bg-dark-green {
  background-color: #029094;
}
#cover-right-widget .crw-bg-light-green {
  background-color: rgba(2, 144, 148, 0.05);
}
#cover-right-widget .crw-white {
  color: white;
}
#cover-right-widget .crw-red {
  color: #f44336 !important;
}
#cover-right-widget .crw-text-primary {
  color: #1c434f !important;
}
#cover-right-widget .crw-pre {
  white-space: pre-line;
}
#cover-right-widget .crw-w-126 {
  width: 126px !important;
}

